import { useFormik } from "formik";
import React, { useState } from "react";
import { clientIdSchemaOnboarding } from "../../formSchemas";
import Button from "../../components/Button";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useGravitonDetailsMutation } from "../../slices/postqueries/postqueriesApi";

const GravitonDetails = () => {
  const navigate = useNavigate();
  const [response, setResponse] = useState(null);
  const [gravitonDetails, { error: gravitonDetailsError, isLoading }] =
    useGravitonDetailsMutation();
  const formik = useFormik({
    initialValues: { clientId: "" },
    validationSchema: clientIdSchemaOnboarding,
    onSubmit: async (values, actions) => {
      try {
        const res = await gravitonDetails({ clientId: values.clientId });

        if (!res.error) {
          toast.success("Successfully Fetched Graviton Details!", {
            autoClose: 2000,
          });
        } else {
          toast.error("Error Fetching Graviton Details!", {
            autoClose: 2000,
          });
        }
        setResponse(res);
      } catch (error) {
        console.log(error);
        if (gravitonDetailsError) {
          toast.error(gravitonDetailsError.error, {
            autoClose: 2000,
          });
        }
        toast.error(error?.data?.message || error?.data, {
          autoClose: 2000,
        });
      }
    },
  });
  if (isLoading) return <div>Loading...</div>;
  const handleGoBack = () => {
    navigate(-1);
  };
  return (
    <div className="w-full h-full">
      <Button
        name="< Go back"
        onClick={handleGoBack}
        classNames="bg-blue-500 text-white px-3 py-1 hover:bg-blue-600 hover:cursor-pointer ml-10 mb-10"
      />
      <h2 className="text-4xl font-bold mb-4 text-center">Graviton Details</h2>
      <form
        onSubmit={formik.handleSubmit}
        className="grid grid-cols-1 shadow-lg rounded-xl mx-10 bg-slate-50 p-5"
      >
        <div className="mb-6">
          <label htmlFor="Details" className="text-3xl font-medium block mb-3">
            Details
          </label>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-4">
            <div className="mb-2">
              <label htmlFor="clientID" className="text-lg ml-1 block">
                ClientId
                <span className="text-lg text-red-500 ml-1">*</span>
              </label>
              <input
                type="text"
                name="clientId"
                placeholder="Enter ClientId"
                value={formik.values.clientId}
                onChange={formik.handleChange}
                className={`rounded-xl p-4 border w-full`}
                required={true}
              />
              {formik.touched.clientId && formik.errors.clientId && (
                <div className="text-red-500 mb-1 ml-1 error text-md">
                  {formik.errors.clientId}
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="flex items-center justify-start mb-6">
          <Button
            name="Submit"
            classNames="bg-blue-500 w-1/3 flex items-center justify-center text-md text-white mt-5 rounded:md md:w-1/4 hover:bg-blue-600"
          />
        </div>
        <div className="w-full bg-gray-white overflow-auto">
          {response && (
            <div className=" flex flex-col">
              <hr className="h-px my-4 bg-gray-200 border-0" />

              <div className="flex justify-between items-center">
                <h3 className="text-2xl">Response:</h3>
              </div>
              <pre className="text-2xl m-2 p-2rounded-xl text-semibold text-green-500 ">
                {JSON.stringify(response?.data || response, null, 2)}
              </pre>
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

export default GravitonDetails;
