import React from "react";

import { useFormik } from "formik";
import {
  useCreateCredentialsMutation,
  useUpdateCredentialsMutation,
} from "../slices/postqueries/postqueriesApi.js";
import Button from "./Button.jsx";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { cibilLayer7Schema, cibilSchema } from "../formSchemas/index.js";

const FormInput = ({
  credential,
  disabled,
  codevalues,
  onSuccess,
  data,
  fields,
  productName,
}) => {
  const { userInfo } = useSelector((state) => state.auth);
  const { clientId } = userInfo;

  const fieldArray = Array.isArray(fields) ? fields : Object.values(fields);

  const [createCredentials, { isError: createIsError, error: createError }] =
    useCreateCredentialsMutation();

  const [updateCredentials, { isError: updateIsError, error: updateError }] =
    useUpdateCredentialsMutation();

  const getProductKey = (value) => {
    for (const key in codevalues) {
      if (codevalues[key].name === value) {
        return key;
      }
    }
    return null;
  };
  const allInitialValues = {};
  fieldArray.forEach((formFields) => {
    if (formFields.name === productName) {
      formFields.fields.forEach((field) => {
        allInitialValues[field] = "";
      });
    }
  });

  const productNameKey = getProductKey(productName);
  const formik = useFormik({
    initialValues: allInitialValues,

    validationSchema:
      productName === "layer7" ? cibilLayer7Schema : cibilSchema,

    onSubmit: async (values, action) => {
      try {
        const credentials = new FormData();
        fieldArray.forEach((formFields) => {
          if (formFields.name === productName) {
            formFields.fields.forEach((field) => {
              credentials[field] = formik.values[field] || "";
            });
          }
        });
        credentials["productName"] = productNameKey;
        if (productName === "layer7") {
          credentials["p12Certificate"] = formik.values["p12Certificate"];
        }
        console.log("credentials : ", credentials);
        if (credential === "Create") {
          const res = await createCredentials({
            credentials,
            product: productName,
            clientId,
          }).unwrap();
          console.log(res);

          if (res) {
            toast.success("Successfully Created credentials!", {
              autoClose: 2000,
            });
          } else {
            toast.error("Error Creating Credentials!");
          }

          const newBody = credentials;

          console.log("NewBody : ", newBody);
          onSuccess(newBody);

          action.resetForm();
        } else {
          const res = await updateCredentials({
            credentials,
            product: productName,
            clientId,
          }).unwrap();
          console.log(res);

          if (res) {
            toast.success("Successfully Updated credentials!", {
              autoClose: 2000,
            });
          } else {
            toast.error("Error Updating Credentials!");
          }

          onSuccess();

          action.resetForm();
        }
      } catch (err) {
        if (createIsError || updateIsError) {
          console.log(
            createIsError
              ? createError
              : updateError || "An error occurred. Please try again later."
          );
          toast.error(
            createIsError
              ? createError.error
              : updateError.error ||
                  "An error occurred. Please try again later."
          );
        } else {
          console.log(err);
          toast.error(err?.data?.error || err.data);
        }
      }
    },
  });

  return (
    <div
      className={`p-5 m-4 bg-white border-2 border-t-gray-100 rounded-3xl shadow-md shadow-gray-300/30 w-4/5 h-4/5 md:w-1/3 md:h-full ${
        disabled ? "opacity-50 border-none cursor-not-allowed bg-red-500" : ""
      }`}
    >
      <h1
        className={`text-3xl text-gray-900 text-center font-semibold  mt-10 mb-8`}
      >
        {credential}{" "}
        {productName.charAt(0).toUpperCase() + productName.slice(1)} Credentials
      </h1>
      <div className="flex flex-col  rounded-lg items-center justify-center gap-y-2 h-4/5 w-full">
        {fieldArray.map((formFields, index) => {
          if (formFields.name === productName) {
            return (
              <form
                onSubmit={formik.handleSubmit}
                key={index}
                className="flex flex-col items-center justify-center gap-y-2 p-10 w-full"
              >
                <div className="w-2/3 flex-col items-center gap-y-4 justify-center mb-5">
                  {formFields.fields.map((field) => (
                    <div key={field}>
                      <label
                        className="text-xl text-gray-600 font-semibold"
                        htmlFor={field}
                      >
                        {field.charAt(0).toUpperCase() + field.slice(1)}
                      </label>

                      <input
                        type="text"
                        id={field}
                        name={field}
                        placeholder={formik.values[field] ? "" : field}
                        value={formik.values[field] || ""}
                        onChange={formik.handleChange}
                        className={`bg-gray-100 mb-5 w-full p-2 rounded-xl border-gray-200 border-2 focus:bg-white focus:border-2 ${
                          disabled ? "cursor-not-allowed" : " "
                        }`}
                        required
                        disabled={disabled}
                      />

                      {formik.touched[field] && formik.errors[field] && (
                        <div className="text-red-500 mb-5 error text-md">
                          {formik.errors[field]}
                        </div>
                      )}
                    </div>
                  ))}
                  {productName === "layer7" && (
                    <div>
                      <label
                        className="text-xl text-gray-600 font-semibold"
                        htmlFor="file"
                      >
                        Upload p12Certificate
                      </label>

                      <input
                        type="file"
                        id="p12Certificate"
                        name="p12Certificate"
                        onChange={(event) =>
                          formik.setFieldValue(
                            "p12Certificate",
                            event.currentTarget.files[0]
                          )
                        }
                        className={`bg-gray-100 mb-5 w-full p-2 rounded-xl border-gray-200 border-2 focus:bg-white focus:border-2 ${
                          disabled ? "cursor-not-allowed" : ""
                        }`}
                        required
                        disabled={disabled}
                      />
                    </div>
                  )}
                </div>
                <Button
                  name={credential}
                  classNames={`bg-blue-500 w-1/2 flex items-center justify-center text-md text-white mt-5 rounded:md md:w-1/4  hover:bg-blue-600 ${
                    disabled ? "cursor-not-allowed bg-blue-500" : ""
                  }`}
                />

                {createError && (
                  <p className="text-red-500 text-md">
                    Error Creating Credentials
                  </p>
                )}
                {updateError && (
                  <p className=" text-red-500 text-md">
                    Error Updating Credentials
                  </p>
                )}
              </form>
            );
          }
          return null;
        })}
      </div>
    </div>
  );
};

export default FormInput;
