import React, { useState, useEffect } from "react";
import { useGetTemplatesWithLimitQuery } from "../../slices/getqueries/getqueriesApi";
import { useSelector } from "react-redux";
import Button from "../../components/Button";
import { useDeleteTemplateMutation } from "../../slices/deletequeries/deletequeriesApi";
import { toast } from "react-toastify";
import ConfirmationModal from "../../components/ConfirmationModal"; // Adjust the import path as needed
import { useNavigate } from "react-router-dom";

const MessageTemplates = () => {
  const [items, setItems] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [templateToDelete, setTemplateToDelete] = useState(null);
  const navigate = useNavigate();

  const { userInfo } = useSelector((state) => state.auth);
  const { clientId, permissions } = userInfo;

  const { data, error, isLoading } = useGetTemplatesWithLimitQuery({
    page,
    clientname: clientId,
  });
  const [deleteTemplate, { isError, error: deleteError }] =
    useDeleteTemplateMutation();

  const handleDelete = async (templateId) => {
    try {
      const res = await deleteTemplate({ templateId, clientname: clientId });
      if (res?.data?.status === "success") {
        toast.success("Successfully Deleted Template!", {
          autoClose: 2000,
        });
        setItems((prevItems) =>
          prevItems.filter((item) => !Object.keys(item).includes(templateId))
        );
      } else {
        toast.error("Error deleting Template");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const confirmDelete = (templateId) => {
    setTemplateToDelete(templateId);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setTemplateToDelete(null);
  };

  const onConfirmDelete = () => {
    if (templateToDelete) {
      handleDelete(templateToDelete);
    }
    closeModal();
  };

  useEffect(() => {
    if (data) {
      if (data.templates) {
        setItems(data.templates);
        const totalItems = data.totalCount;
        const newTotalPages = Math.ceil(totalItems / data.limit);
        setTotalPages(newTotalPages);
      } else {
        setItems([]);
        setTotalPages(1);
      }
    }
  }, [data, page]);

  useEffect(() => {
    if (items.length === 0 && page > 1) {
      setPage((prevPage) => prevPage - 1);
      setTotalPages(page);
    }
  }, [items, page, totalPages]);

  const nextPage = () => {
    setPage((prevPage) => (prevPage < totalPages ? prevPage + 1 : prevPage));
  };

  const prevPage = () => setPage((prevPage) => Math.max(prevPage - 1, 1));

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <div className="w-full h-full">
      <Button
        name="< Go back"
        onClick={handleGoBack}
        classNames="bg-blue-500 text-white px-3 py-1 hover:bg-blue-600 hover:cursor-pointer ml-10 mb-4"
      />
      <div className="flex flex-col items-center justify-center p-3 rounded-xl">
        <h1 className="text-3xl font-bold font-serif mb-4">
          Message Templates
        </h1>

        <div className="w-full h-full">
          <div className="flex flex-col items-center h-full rounded-xl">
            <div className="flex justify-center md:justify-end lg:justify-end items-center w-full md:w-2/3 lg:2/3 m-3 ">
              <Button
                name="previous"
                classNames={`text-blue-500 border border-blue-500 p-2 m-2 hover:bg-gray-100 ${
                  page === 1 ? "disabled opacity-50 cursor-not-allowed " : ""
                }`}
                onClick={prevPage}
              />
              <span className="bg-blue-500 text-white border border-blue-500 font-bold text-xl py-2  px-4 rounded-lg">
                {page}
              </span>
              <Button
                name="next"
                classNames={`text-blue-500 border border-blue-500 p-2 m-2 hover:bg-gray-100 ${
                  page === totalPages
                    ? "disabled  opacity-50 cursor-not-allowed"
                    : ""
                }`}
                onClick={nextPage}
              />
            </div>
            {items.length === 0 ? (
              <div className="text-2xl text-red-500">No templates found</div>
            ) : (
              // <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-4 w-full md:w-1/2 lg:w-1/2">
              //   {items.map((item, index) => {
              //     const templateIds = Object.keys(item).filter(
              //       (id) => id !== "sampleTemplate"
              //     );
              //     return (
              //       <div
              //         key={index}
              //         className="w-full bg-white shadow-md mb-10 p-10 border rounded-lg"
              //       >
              //         {templateIds.map((templateId) => (
              //           <div key={templateId} className="mb-2 text-2xl">
              //             <div className="w-full flex flex-col ">
              //               <div className="flex justify-between items-center ">
              //                 <strong className="text-2xl font-serif mb-2">
              //                   Template ID:
              //                 </strong>{" "}
              //                 {permissions.map((permission) => {
              //                   if (permission === "DELETE_TEMPLATEWITHID") {
              //                     return (
              //                       <button
              //                         className="mb-4 text-lg bg-red-500 hover:bg-red-600  text-white font-medium px-3 py-1 cursor-pointer  rounded-md "
              //                         onClick={() => confirmDelete(templateId)}
              //                       >
              //                         Delete
              //                       </button>
              //                     );
              //                   }
              //                 })}
              //               </div>
              //               <span className="text-2xl mb-2 font-bold bg-slate-100 px-4 py-4 rounded-lg text-red-500 break-words">
              //                 {templateId}
              //               </span>
              //             </div>
              //             <hr className="h-px my-2 bg-gray-200 border-0"></hr>
              //             <div className="w-full flex flex-col  mt-4 mb-4">
              //               <strong className="mb-2 font-serif">Fields:</strong>
              //               <ul className="bg-slate-100 w-full lg:w-1/2 p-4 mb-2 rounded-2xl">
              //                 <li className="border-none p-0 w-full">
              //                   <div className="flex justify-between px-10 items-center w-full">
              //                     <strong className="text-2xl ">key</strong>
              //                     <strong className="text-2xl  ">Type</strong>
              //                   </div>
              //                 </li>
              //                 {Object.entries(item[templateId]).map(
              //                   ([key, value]) => (
              //                     <li
              //                       key={key}
              //                       className="border-none p-0 w-full"
              //                     >
              //                       <div className="flex justify-between gap-x-4  px-10 items-center w-full">
              //                         <span className="text-2xl font-bold text-green-500 ">
              //                           {key}
              //                         </span>
              //                         <span className="text-2xl font-bold  text-green-500">
              //                           {value}
              //                         </span>
              //                       </div>
              //                     </li>
              //                   )
              //                 )}
              //               </ul>
              //             </div>

              //             {item.sampleTemplate ? (
              //               <div>
              //                 <hr className="h-px my-2 bg-gray-200 border-0 "></hr>
              //                 <div className="w-full flex flex-col text-wrap">
              //                   <strong className="text-2xl font-serif mb-2">
              //                     Sample Template:
              //                   </strong>{" "}
              //                   <p className="bg-gray-100 text-wrap font-bold px-3 py-4 rounded-lg text-2xl text-green-500 overflow-wrap break-words">
              //                     {item.sampleTemplate}
              //                   </p>
              //                 </div>
              //               </div>
              //             ) : (
              //               <div>
              //                 <hr className="h-px my-2 bg-gray-200 border-0 "></hr>
              //                 <div className="w-full flex flex-col text-wrap">
              //                   <strong className="text-2xl font-serif mb-2">
              //                     Sample Template:
              //                   </strong>{" "}
              //                   <p className="bg-gray-100 text-wrap font-bold px-3 py-4 rounded-lg text-2xl text-red-500 overflow-wrap break-words">
              //                     No Sample Template present for this
              //                     templateId!!!
              //                   </p>
              //                 </div>
              //               </div>
              //             )}
              //           </div>
              //         ))}
              //       </div>
              //     );
              //   })}
              // </div>
              <div className="grid grid-cols-1 gap-2 w-full  lg:w-2/3">
                {items.map((item, index) => {
                  const templateIds = Object.keys(item).filter(
                    (id) => id !== "sampleTemplate"
                  );

                  return (
                    <div className="grid grid-cols-1 bg-white mb-10 p-10 rounded-xl shadow-lg">
                      <div className="w-full flex justify-end px-4">
                        {templateIds.map((templateId) => {
                          return (
                            <div key={templateId}>
                              {permissions.map((permission) => {
                                if (permission === "DELETE_TEMPLATEWITHID") {
                                  return (
                                    <button
                                      key={`${templateId}-${permission}`}
                                      className="mb-4 text-lg bg-red-500 hover:bg-red-600 text-white font-medium px-3 py-1 cursor-pointer rounded-md"
                                      onClick={() => confirmDelete(templateId)}
                                    >
                                      Delete
                                    </button>
                                  );
                                }
                                return null; // Return null if the condition is not met to avoid undefined elements in the list
                              })}
                            </div>
                          );
                        })}
                      </div>
                      <div
                        key={index}
                        className="grid grid-cols-1 md:grid-cols-2 "
                      >
                        <div className="grid grid-cols-1 p-4">
                          <div className="mb-2  flex flex-col items-start justify-center">
                            <h1 className="text-3xl font-serif mb-4 text-gray-700 font-semibold">
                              Template ID:
                            </h1>
                            {templateIds.map((templateId) => {
                              return (
                                <div className=" text-wrap font-bold rounded-lg w-full ">
                                  <span className="text-2xl text-gray-500 mb-4 font-semibold overflow-wrap break-words">
                                    {templateId}
                                  </span>
                                </div>
                              );
                            })}
                          </div>
                          <hr className="h-px my-3  bg-gray-200 border-0 "></hr>
                          <div className="flex flex-col items-start">
                            {item.sampleTemplate ? (
                              <div className="w-full flex flex-col text-wrap">
                                <strong className="text-3xl mb-4 font-serif  text-gray-700 font-semibold">
                                  Sample Template:
                                </strong>{" "}
                                <p className=" text-wrap font-bold  rounded-lg text-2xl text-green-500 overflow-wrap break-words">
                                  {item.sampleTemplate}
                                </p>
                              </div>
                            ) : (
                              <div className="w-full flex flex-col text-wrap">
                                <strong className="text-3xl mb-2 font-serif  text-gray-700 font-semibold">
                                  Sample Template:
                                </strong>{" "}
                                <p className=" text-wrap font-bold  rounded-lg text-2xl text-red-500 overflow-wrap break-words">
                                  No Sample Template present for this
                                  templateId!!!
                                </p>
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="grid grid-cols-1 w-full gap-2 p-4">
                          <strong className="text-3xl font-serif mb-2 text-gray-700  font-semibold">
                            Fields:
                          </strong>

                          <div className="grid grid-cols-2 bg-gray-100 py-2 text-wrap font-bold rounded-lg w-full">
                            <span className="text-2xl text-gray-600 font-semibold text-center">
                              Key
                            </span>
                            <span className="text-2xl text-gray-600 font-semibold text-center">
                              Type
                            </span>
                          </div>

                          {templateIds.map((templateId) => {
                            return (
                              <div key={templateId}>
                                <div className="grid grid-cols-1 p-1 gap-2 text-gray-500">
                                  <ul className="w-full">
                                    {Object.entries(item[templateId]).map(
                                      ([key, value]) => (
                                        <li
                                          key={key}
                                          className="border-none p-0 w-full"
                                        >
                                          <div className="grid grid-cols-2 justify-around items-center  rounded-xl gap-4 p-2">
                                            <span className="text-xl text-gray-500 font-semibold text-center break-words">
                                              {key}
                                            </span>
                                            <span className="text-xl text-gray-500 font-semibold text-center break-words">
                                              {value}
                                            </span>
                                          </div>
                                          {/* <hr className="h-px bg-gray-200 border-0 "></hr> */}
                                        </li>
                                      )
                                    )}
                                  </ul>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </div>
      {isError && (
        <div className="text-red-500">Error: {deleteError.message}</div>
      )}
      <ConfirmationModal
        isOpen={isModalOpen}
        onClose={closeModal}
        onConfirm={onConfirmDelete}
        message="Are you sure you want to delete this template?"
      />
    </div>
  );
};

export default MessageTemplates;
