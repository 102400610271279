import React from 'react'

const Button = (props) => {
  const {classNames,name,onClick} = props;
  const defaultClasses = "font-bold py-3 px-8";
  const buttonClasses = classNames ? `${defaultClasses} ${classNames}` : `${defaultClasses} bg-white hover:bg-gray-200 text-primary rounded`
  return (
    <button className={buttonClasses} onClick={onClick}>{name}</button>
  )
}

export default Button