// import React, { useState } from "react";
// import { useSelector } from "react-redux";
// import { GET_FINANCIAL_REPORT } from "../constants";
// import Cookies from "js-cookie";

// const FinancialReport = () => {
//   const { userInfo } = useSelector((state) => state.auth);
//   const { clientId } = userInfo;
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState(null);
//   const [progress, setProgress] = useState(0);

//   const downloadFinancialReport = async ({ clientname, year }) => {
//     try {
//       setLoading(true);
//       setError(null);
//       setProgress(0);
//       const token = Cookies.get("jwt");
//       const headers = {
//         Token: token,
//       };

//       const response = await fetch(
//         `http://localhost:9005/${GET_FINANCIAL_REPORT}/${clientname}?year=${year}`,
//         {
//           method: "GET",
//           headers: headers,
//         }
//       );

//       if (!response.ok) {
//         throw new Error(`HTTP error! Status: ${response.status}`);
//       }

//       const reader = response.body.getReader();
//       const contentLength = +response.headers.get("content-length");
//       let receivedLength = 0;

//       const chunks = [];

//       while (true) {
//         const { done, value } = await reader.read();

//         if (done) {
//           setProgress(100);
//           break;
//         }

//         chunks.push(value);
//         receivedLength += value.length;

//         setProgress((receivedLength / contentLength) * 100);
//       }

//       const blob = new Blob(chunks);

//       const link = document.createElement("a");
//       link.href = window.URL.createObjectURL(blob);
//       link.download = "report.xlsx";
//       document.body.appendChild(link);
//       link.click();
//       document.body.removeChild(link);
//     } catch (error) {
//       console.error("Error downloading file:", error);
//       setError("Failed to download file. Please try again later.");
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handleGetReport = () => {
//     downloadFinancialReport({ clientname: "testing", year: "2024" });
//   };

//   return (
//     <>
//       <div className="financialreportpage">
//         <button
//           onClick={handleGetReport}
//           className="bg-slate-700 h-8 w-28 text-white hover:bg-slate-500"
//           disabled={loading}
//         >
//           {loading ? "Downloading..." : "Get Report"}
//         </button>

//         {progress >= 0 && (
//           <div style={{ marginTop: "1px" }}>
//             <progress value={progress} max="100" />
//             <span>{progress === 100 ? "Complete" : `${progress}%`}</span>
//           </div>
//         )}
//         {error && <p style={{ color: "red" }}>{error}</p>}
//       </div>
//     </>
//   );
// };

// export default FinancialReport;

import React, { useState } from "react";
import { useSelector } from "react-redux";
import { BASE_URL, GET_FINANCIAL_REPORT } from "../constants";
import Cookies from "js-cookie";
import LinearProgress from "@mui/material/LinearProgress";
import DropDown from "../components/DropDown";

const FinancialReport = () => {
  const { userInfo } = useSelector((state) => state.auth);
  const { clientId, isAdmin } = userInfo;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [progress, setProgress] = useState(0);
  const d = new Date();
  let yearvalue = d.getFullYear();
  const [year, setYear] = useState(yearvalue);
  let options = [];
  for (let i = 2023; i <= parseInt(yearvalue); i++) {
    options.push(i);
  }
  console.log(options);
  const downloadFinancialReport = async ({ clientname, year }) => {
    try {
      setLoading(true);
      setError(null);
      setProgress(0);
      const token = Cookies.get("jwt");
      const headers = {
        Token: token,
      };

      const response = await fetch(
        `${BASE_URL}${GET_FINANCIAL_REPORT}/${clientname}?year=${year}`,
        {
          method: "GET",
          headers: headers,
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const reader = response.body.getReader();
      const contentLength = +response.headers.get("content-length");
      let receivedLength = 0;

      const chunks = [];

      while (true) {
        const { done, value } = await reader.read();

        if (done) {
          setProgress(100);
          break;
        }

        chunks.push(value);
        receivedLength += value.length;

        setProgress((receivedLength / contentLength) * 100);
      }

      const blob = new Blob(chunks);

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "report.xlsx";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading file:", error);
      setError("Failed to download file. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const handleGetReport = () => {
    downloadFinancialReport({ clientname: clientId, year: year });
  };

  const handleChange = (event) => {
    setYear(event.target.value);
  };

  return (
    <>
      <div className="financialreportpage">
        <div className="financialcontainer">
          <h1 className="text-2xl font-bold text-center">
            Select Financial Year
          </h1>
          <DropDown
            selectedOption={year}
            options={options}
            handleChange={handleChange}
            type="Select Year"
            addedClass={"w-full md:w-auto"}
          />
          {progress >= 0 && (
            <div style={{ marginTop: "10px" }}>
              <LinearProgress variant="determinate" value={progress} />
              <span>{progress === 100 ? "Complete" : `${progress}%`}</span>
            </div>
          )}
          {error && <p style={{ color: "red" }}>{error}</p>}
          <a
            className={`relative rounded px-5 py-2.5 overflow-hidden group bg-slate-700 cursor-pointer ${
              loading
                ? "cursor-not-allowed"
                : "hover:bg-gradient-to-r hover:from-slate-700 hover:to-slate-500 hover:ring-2 hover:ring-offset-2 hover:ring-slate-400"
            } text-white hover:text-gray-200 transition-all ease-out duration-300`}
            onClick={handleGetReport}
            style={{ pointerEvents: loading ? "none" : "auto" }}
          >
            <span className="absolute right-0 w-8 h-32 -mt-12 transition-all duration-1000 transform translate-x-12 bg-white opacity-10 rotate-12 group-hover:-translate-x-40 ease"></span>
            <span className="relative flex items-center justify-center">
              {loading ? "Downloading..." : "Get Report"}
            </span>
          </a>
        </div>
      </div>
    </>
  );
};

export default FinancialReport;
