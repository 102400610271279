import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import FormInput from "../components/FormInput";
import {
  useGetCibilCredentialQuery,
  useGetCodeValuesQuery,
} from "../slices/getqueries/getqueriesApi";

const CibilCredential = () => {
  const { userInfo } = useSelector((state) => state.auth);
  const { clientId } = userInfo;
  const [documentExists, setDocumentExists] = useState(false);
  const [mappedValue, setMappedValue] = useState({});
  const { data: currentResponseData } = useGetCibilCredentialQuery({
    clientname: clientId,
  });

  const { data: codevalues } = useGetCodeValuesQuery();
  console.log(codevalues);
  const codeValuesArray = codevalues
    ? Object.values(codevalues).map((item) => item.name)
    : [];
  console.log("codeValuesArray : ", codeValuesArray);
  const fieldsObject = codevalues
    ? Object.values(codevalues).map((item) => ({
        name: item.name,
        fields: item.fields,
      }))
    : [];
  const fields = fieldsObject.reduce((acc, curr) => {
    acc[curr.name] = curr.fields;
    return acc;
  }, {});

  console.log("fields with name:", fieldsObject);
  console.log("fields with name: ", fields);

  useEffect(() => {
    if (currentResponseData && codevalues) {
      setDocumentExists(true);
      const mappedValue = codeValuesArray.reduce((acc, element) => {
        const match = currentResponseData.find(
          (response) => response.productName === element
        );
        acc[element] = match ? match : null;
        return acc;
      }, {});
      setMappedValue(mappedValue);
    }
  }, [currentResponseData, codevalues]);

  const productArrays = currentResponseData;

  const handleSuccess = (productName, { newData }) => {
    setDocumentExists(true);

    const updatedMappedValue = { ...mappedValue, [productName]: newData };
    setMappedValue(updatedMappedValue);
    window.location.reload();
  };

  return (
    <div className="p-4  w-full h-full">
      <div className="flex flex-col items-start h-full">
        <div className="flex flex-col items-center justify-center w-full">
          <div className="w-2/3 flex flex-start">
            <h1 className="text-4xl mb-4 font-medium">
              Create or Update your cibil credentials.
            </h1>
          </div>
          {Object.keys(mappedValue).map((key) => (
            <React.Fragment key={key}>
              <div className="w-2/3">
                {/* <h1 className="mb-2 text-3xl text-left font-semibold">
                  Product: {key}
                </h1> */}
              </div>

              <div className="flex flex-col md:flex-row justify-center items-center w-full mb-10">
                {productArrays &&
                  productArrays.map((product) => (
                    <React.Fragment key={product.productName}>
                      {product.productName === key && (
                        <>
                          <FormInput
                            productName={key}
                            data={mappedValue[key]}
                            codevalues={codevalues}
                            credential="Update"
                            disabled={false}
                            onSuccess={() => setDocumentExists(false)}
                            fields={fieldsObject}
                          />
                          <FormInput
                            productName={key}
                            data={mappedValue[key]}
                            codevalues={codevalues}
                            credential="Create"
                            fields={fieldsObject}
                            disabled={true}
                          />
                        </>
                      )}
                    </React.Fragment>
                  ))}

                {(!productArrays ||
                  !productArrays.some(
                    (product) => product.productName === key
                  )) && (
                  <>
                    <FormInput
                      productName={key}
                      data={mappedValue[key]}
                      codevalues={codevalues}
                      credential="Create"
                      disabled={false}
                      fields={fieldsObject}
                      onSuccess={(newData) => handleSuccess(key, newData)}
                    />
                    <FormInput
                      productName={key}
                      data={mappedValue[key]}
                      credential="Update"
                      codevalues={codevalues}
                      fields={fieldsObject}
                      disabled={true}
                    />
                  </>
                )}
              </div>
            </React.Fragment>
          ))}
          {Object.keys(mappedValue).length === 0 &&
            codeValuesArray.map((product) => (
              <div
                key={product}
                className="flex flex-col md:flex-row justify-center items-center w-full mb-10"
              >
                <FormInput
                  productName={product}
                  fields={fieldsObject}
                  data={null}
                  codevalues={codevalues}
                  credential="Create"
                  disabled={false}
                  onSuccess={(newData) => handleSuccess(product, newData)}
                />
                <FormInput
                  productName={product}
                  data={null}
                  credential="Update"
                  fields={fieldsObject}
                  codevalues={codevalues}
                  disabled={true}
                />
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default CibilCredential;
