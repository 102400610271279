import React from "react";
import { useSelector } from "react-redux";
import { useGetCurrentPlanQuery } from "../slices/getqueries/getqueriesApi";
import PlanCard from "../components/PlanCard";
const CurrentPlan = () => {
  const { userInfo } = useSelector((state) => state.auth);
  const { clientId, isAdmin } = userInfo;
  const { data: currentplandata, error: errorcurrentplandata } =
    useGetCurrentPlanQuery({ clientname: clientId });
  console.log(currentplandata);
  return (
    <>
      <div className="currentplanpage">
        <h1 className="text-3xl p-3">Current Plan Details: </h1>
        <div className="currentplancards">
          {currentplandata?.map((item, index) => (
            <PlanCard
              key={index}
              serviceName={item.modulename}
              amount={item.chargepercall}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default CurrentPlan;
