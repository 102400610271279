import { DELETE_TEMPLATE_URL } from "../../constants";
import { apiSlice } from "../apislice";

export const DeleteApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    deleteTemplate: builder.mutation({
      query: ({ templateId, clientname }) => ({
        url: `${DELETE_TEMPLATE_URL}/${templateId}?clientname=${clientname}`,
        method: "DELETE",
      }),
      withCredentials: true,
      credentials: "include",
    }),
  }),
});
export const { useDeleteTemplateMutation } = DeleteApiSlice;
