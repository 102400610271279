import React, { useEffect, useState } from "react";
import { useGetRevenueDetailsQuery } from "../slices/getqueries/getqueriesApi";
import { useSelector } from "react-redux";
import { Line } from "react-chartjs-2";
import Chart from "chart.js/auto";
import DropDown from "./DropDown";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const getMonth = (monthnumber) => {
  const month = months[monthnumber - 1];
  return month;
};

const getYears = () => {
  const currentYear = new Date().getFullYear();
  const startYear = 2020;
  const years = [];
  for (let year = startYear; year <= currentYear; year++) {
    years.push(year);
  }
  return years;
};

const LineChartComponent = () => {
  const { userInfo } = useSelector((state) => state.auth);
  const { clientId } = userInfo;
  const [totalChargePerCall, setTotalChargePerCall] = useState(0);
  const [monthlyChargePerCall, setMonthlyChargePerCall] = useState([]);
  const [selectedYear, setSelectedYear] = useState(
    String(new Date().getFullYear())
  );

  const { data, error, isLoading } = useGetRevenueDetailsQuery({
    clientId: clientId,
    year: selectedYear,
  });

  useEffect(() => {
    if (data) {
      const resultsArray = data.results;
      if (
        resultsArray &&
        Array.isArray(resultsArray) &&
        resultsArray.length > 0
      ) {
        setMonthlyChargePerCall(resultsArray);
      }
      if (data.totalChargePerCall) {
        setTotalChargePerCall(data.totalChargePerCall);
      }
    }
  }, [data]);

  const handleChangeYear = (e) => {
    setSelectedYear(e.target.value);
  };

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <div className="w-full h-auto">
      <div className="">
        <div className="grid grid-cols-1 shadow-xl rounded-2xl bg-gray-50 md:grid-cols-2 gap-4">
          <div className=" m-4 p-1">
            <div className="flex justify-around items-center mx-3 px-4">
              <div>
                <h1 className="text-3xl mt-10 text-gray-500 font-semibold">
                  Credits Used
                </h1>
                {data.totalChargePerCall > 0 ? (
                  <span className="text-5xl text-gray-400 font-semibold">
                    {totalChargePerCall}
                  </span>
                ) : (
                  <span className="text-xl text-red-500">
                    No Credits to display
                  </span>
                )}
              </div>
              <div className="grid grid-cols-1 gap-2 p-2">
                <DropDown
                  selectedOption={selectedYear}
                  options={getYears()}
                  handleChange={handleChangeYear}
                  type="Select Year"
                  addedClass="w-full md:w-auto"
                />
              </div>
            </div>

            {data.results !== null ? (
              <div className="flex justify-center items-center m-4 p-4">
                <Line
                  data={{
                    labels: monthlyChargePerCall.map(
                      (mc) => `${getMonth(mc.month)} ${mc.year}`
                    ),
                    datasets: [
                      {
                        label: `Credits Used`,
                        data: monthlyChargePerCall.map(
                          (mc) => mc.sumchargepercall
                        ),
                        fill: true,
                        borderColor: "rgb(99 102 241)",
                        tension: 0.1,
                        backgroundColor: "rgba(165 ,180 ,252, 0.2)",
                        pointBackgroundColor: "rgb(99 102 241)",
                        pointBorderColor: "white",
                        pointBorderWidth: 2,
                        pointRadius: 5,
                      },
                    ],
                  }}
                  options={{
                    scales: {
                      x: {
                        grid: {
                          display: false,
                        },
                      },
                      y: {
                        grid: {
                          display: false,
                        },
                      },
                    },
                  }}
                />
              </div>
            ) : (
              <div className="flex justify-center items-center">
                {/* <span className="text-2xl text-red-500">
                    No data to display
                  </span> */}
              </div>
            )}
          </div>
          <div className="flex flex-col bg-gray-50 m-4  p-4 justify-start gap-2">
            {data.results !== null ? (
              <h1 className="text-3xl text-center text-gray-600 gap-2 font-bold break-words">
                Monthly services used
              </h1>
            ) : (
              <div className="flex justify-center items-center">
                <span className="text-2xl mt-10 text-red-500">
                  No data to display
                </span>
              </div>
            )}
            {data.results !== null ? (
              <div className="grid w-full gap-2 p-4">
                <div className="grid grid-cols-3 justify-around items-center bg-gray-100 rounded-xl mb-4 p-2 gap-4">
                  <span className="text-2xl text-gray-600 font-semibold break-words text-center">
                    Year
                  </span>
                  <span className="text-2xl text-gray-600 font-semibold break-words text-center">
                    Month
                  </span>
                  <span className="text-2xl text-gray-600 font-semibold break-words text-center">
                    Charge
                  </span>
                </div>

                {monthlyChargePerCall.map((monthlyCredit) => (
                  <div key={monthlyCredit.month}>
                    <div className="grid grid-cols-3 p-1 gap-2 text-gray-500">
                      <span className="text-xl text-gray-500 font-semibold break-words text-center">
                        {monthlyCredit.year}
                      </span>
                      <span className="text-xl text-gray-500 font-semibold break-words text-center">
                        {getMonth(monthlyCredit.month)}
                      </span>
                      <span className="text-xl text-gray-500 font-semibold break-words text-center">
                        {monthlyCredit.sumchargepercall}
                      </span>
                    </div>
                    <hr className="h-px my-4 bg-gray-200 border-0" />
                  </div>
                ))}
              </div>
            ) : (
              <div className="flex justify-center items-center">
                {/* <span className="text-2xl text-red-500">
                  No data to display
                </span> */}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LineChartComponent;
