export const changeDate = (date) => {
  const inputDate = new Date(date);
  inputDate.setUTCHours(inputDate.getUTCHours() + 5);
  inputDate.setUTCMinutes(inputDate.getUTCMinutes() + 30);
  const year = inputDate.getUTCFullYear();
  const month = String(inputDate.getUTCMonth() + 1).padStart(2, "0"); // Months are 0-based, so add 1
  const day = String(inputDate.getUTCDate()).padStart(2, "0");
  const hours = String(inputDate.getUTCHours()).padStart(2, "0");
  const minutes = String(inputDate.getUTCMinutes()).padStart(2, "0");
  const seconds = String(inputDate.getUTCSeconds()).padStart(2, "0");
  const formattedDateString = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  return formattedDateString;
};

export const getDateWithTimeEndpoint = (timeinday) => {
  const now = new Date();
  const HoursAgo = new Date(now - (timeinday - 1) * 24 * 60 * 60 * 1000);
  HoursAgo.setHours(0, 0, 0, 0);
  const from = HoursAgo.toISOString();
  const fromdate = changeDate(from);
  const to = now.toISOString();
  const todate = changeDate(to);
  const endpoint = `from=${fromdate}&to=${todate}`;
  return { endpoint, fromdate, todate };
};
