import React, { useEffect, useState, useReducer } from "react";
import ServiceCard from "../components/ServiceCard";
import SecondHeader from "../components/SecondHeader";
import Button from "../components/Button";
import Message from "../components/Message";
import {
  useGetGroupDataQuery,
  useGetClientCreditsQuery,
  useGetAllClientsQuery,
} from "../slices/getqueries/getqueriesApi";
import Loader from "../components/Loader";
import { useDispatch, useSelector } from "react-redux";
import { setCredentials } from "../slices/authslice";
import { NavLink } from "react-router-dom";
import LineChartComponent from "../components/LineChartComponent.jsx";
import BarChartComponent from "../components/BarChartComponent.jsx";

const HomeScreen = () => {
  const reduxdispatch = useDispatch();
  const [date, setdate] = useState("");
  const { selectedDatefrom, selectedDateto } = useSelector(
    (state) => state.dateandtime
  );
  const { userInfo } = useSelector((state) => state.auth);
  const { clientId, isAdmin } = userInfo;
  const { data: creditdata, error: errorcreditsdata } =
    useGetClientCreditsQuery(clientId);
  const {
    data: groupdata,
    isLoading: loadingGroupData,
    error: errorGroupData,
  } = useGetGroupDataQuery({ date, clientname: clientId });
  const {
    data: getAllClients,
    isLoading: loadingAllClients,
    error: errorAllClients,
  } = useGetAllClientsQuery({ clientname: clientId });

  useEffect(() => {
    var endpoint = `from=${selectedDatefrom}&to=${selectedDateto}`;
    changeGroupdatafordate(endpoint);
  }, []);

  useEffect(() => {
    dispatch({ type: "SET_DATA", payload: groupdata });
  }, [date, groupdata]);

  function reducer(state, action) {
    switch (action.type) {
      case "SET_CARD":
        return {
          ...state,
          card: action.payload,
          groupdataState:
            action.payload === 0
              ? groupdata
              : groupdata?.filter((data) => data.groupid === action.payload),
        };
      case "SET_DATA":
        return {
          ...state,
          groupdataState: action.payload,
        };
      default:
        return state;
    }
  }
  const [state, dispatch] = useReducer(reducer, {
    card: 0,
    groupdataState: groupdata,
  });

  const changeGroupdatastate = (cardstate) => {
    dispatch({ type: "SET_CARD", payload: cardstate });
  };
  const changeGroupdatafordate = (date) => {
    setdate(date);
  };
  // Access state using state.card and state.groupdataState
  const { card, groupdataState } = state;
  const [isActive, setIsActive] = useState(false);

  const toggleHamburger = () => {
    setIsActive(!isActive);
  };

  const options = getAllClients?.map((item) => {
    return item.clientname;
  });

  const handleChange = (event) => {
    reduxdispatch(setCredentials({ clientId: event.target.value, isAdmin }));
  };
  return (
    <>
      {loadingGroupData ? (
        <Loader />
      ) : errorGroupData ? (
        <Message variant="danger">
          {errorGroupData?.data?.message || errorGroupData.error}
        </Message>
      ) : (
        <>
          {/* {isActive && (
            <div
              className={`${isActive ? "leftcontainerfl-unorder__off" : ""}`}
            >
              <div className="leftcontainerfl flex-col">
                <ul className="leftcontainerfl-unorder items-center">
                  <li className="leftcontainerfl-unorder__list fs-5">
                    <LuCircleDotDashed className="text-primary text-2xl" />
                    <span className="text-primary text-2xl">Dashboard</span>
                  </li>
                  {isAdmin && (
                    <div className="flex items-center flex-col">
                      <li className="text-2xl text-white bg-red-500 border-black">
                        Admin User
                      </li>
                      <DropDown
                        selectedOption={clientId}
                        options={options}
                        handleChange={handleChange}
                        type="Select Client"
                        addedClass={"w-full md:w-auto"}
                      />
                    </div>
                  )}
                  {isAdmin && (
                    <Link
                      className="font-semibold text-lg"
                      to={"/cibilcredential"}
                    >
                      Cibil Credential
                    </Link>
                  )}
                </ul>
              </div>
            </div>
          )} */}
          <div className="middlecontainer md:px-40 lg:px-40">
            <SecondHeader
              onChildClick={changeGroupdatastate}
              onChangeGroupdate={changeGroupdatafordate}
            />
            <div className="servicecards">
              {groupdataState && groupdataState.length > 0 ? (
                groupdataState.map((item) => (
                  <ServiceCard
                    key={item.moduleid}
                    moduleid={item.moduleid}
                    modulename={item.modulename}
                    datalogCount={item.datalogCount}
                    price={item.sumchargepercall.toFixed(2)}
                  />
                ))
              ) : (
                <>
                  <div className=" flex flex-col justify-center items-center">
                    <img
                      src={require("../assets/nodata.png")}
                      alt="No data to display"
                      className="size-48"
                    />
                    <span className="text-xl text-red-500">
                      No data to display
                    </span>
                  </div>
                </>
              )}
            </div>
          </div>

          <div className="rightcontainer sm:w-full flex flex-col">
            <div className="rightcontainer-card1 md:w-96 lg:w-96 rounded-3xl">
              <h2 className="text-3xl">Credit Balance</h2>
              <p className="text-base">Total Amount Remaining</p>
              <h3 className="text-3xl font-light">
                ₹{creditdata?.creditbalance.toFixed(2)}
              </h3>
              <NavLink to={"/transactionHistory"}>
                <Button name="Report" />
              </NavLink>
            </div>

            <div className="rightcontainer-card2 md:w-96 lg:w-96 bg-slate-800">
              <h2 className="text-3xl">Total Consumption</h2>
              <h3 className="text-3xl font-light">
                ₹
                {groupdataState
                  ? groupdataState
                      .reduce((accumulator, item) => {
                        return accumulator + item.sumchargepercall;
                      }, 0)
                      .toFixed(2)
                  : 0}
              </h3>
            </div>
          </div>

          <div className="analytics-container">
            <LineChartComponent />
          </div>
          <div className="analytics-container">
            <BarChartComponent />
          </div>
        </>
      )}
    </>
  );
};

export default HomeScreen;
