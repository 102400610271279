import React from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";

const DropDown = ({
  selectedOption,
  options,
  handleChange,
  type,
  addedClass,
}) => {
  return (
    <>
      <Box
        sx={{ minWidth: 120 }}
        className={`mt-10 ml-4 px-2 md:ml-0 ${addedClass}`}
      >
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">{type}</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedOption}
            label="Select Time"
            onChange={handleChange}
          >
            {options?.map((option) => (
              <MenuItem
                key={option}
                value={option}
                style={{ minWidth: "1000%" }}
              >
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </>
  );
};

export default DropDown;
