import React from "react";

const PlanCard = ({ serviceName, amount }) => {
  return (
    // <div className="max-w-xs rounded overflow-hidden shadow-lg bg-white h-28">
    //   <div className="px-6 py-4">
    //     <div className="font-bold text-xl mb-2">{serviceName}</div>
    //     <p className="text-gray-700 text-base">{`Amount: ₹${amount}`}</p>
    //   </div>
    // </div>
    <div className="custom-card border border-blue-100 text-blue-500 border-1 bg-white shadow-md shadow-blue-500/50 flex flex-col justify-center items-center rounded-2xl hover:text-white hover:bg-blue-500">
      <div className="card-body flex flex-col gap-4 items-center">
        <h2 className="text-2xl font-light">{serviceName}</h2>
        <h3 className="text-xl font-light">Charge: ₹ {amount}</h3>
      </div>
    </div>
  );
};

export default PlanCard;
