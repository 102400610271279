import React, { useState } from "react";
import { FaBars, FaKey, FaMessage, FaRegMessage } from "react-icons/fa6";
import {
  TbAnalyze,
  TbBrandGoogleAnalytics,
  TbMessage2Bolt,
  TbMessage2Plus,
  TbMessageReport,
  TbMessages,
  TbReportAnalytics,
  TbUserPlus,
} from "react-icons/tb";
import { FaMoneyCheckDollar } from "react-icons/fa6";
import { FaBarsStaggered } from "react-icons/fa6";
import { NavLink } from "react-router-dom";
import { GiReceiveMoney } from "react-icons/gi";
import { RiAdminFill } from "react-icons/ri";
import { useGetAllClientsQuery } from "../slices/getqueries/getqueriesApi";
import { useDispatch, useSelector } from "react-redux";
import { setCredentials } from "../slices/authslice";
import DropDown from "./DropDown";

const menuItem = [
  {
    path: "/cibilcredential",
    name: "CibilCredential",
    icon: <FaKey />,
    permissions: ["POST_CREATECREDENTIALS"],
  },
  {
    path: "/financialreport",
    name: "FinancialReport",
    icon: <TbReportAnalytics />,
    permissions: ["GET_FINANCIALREPORT"],
  },
  {
    path: "/currentplan",
    name: "CurrentPlan",
    icon: <FaMoneyCheckDollar />,
    permissions: ["GET_PRICINGDETAILS"],
  },
  {
    path: "/recharge",
    name: "Recharge",
    icon: <GiReceiveMoney />,
    permissions: [
      "POST_VERIFYOTPPAYMENTDASHBOARD",
      "POST_RETRYOTPPAYMENTDASHBOARD",
      "POST_SENDOTPPAYMENTDASHBOARD",
    ],
  },
  {
    path: "/messaging",
    name: "Messaging",
    icon: <TbMessages />,
    permissions: [
      "POST_CREATEMESSAGINGTEMPLATE",
      "GET_TEMPLATESWITHLIMIT",
      "DELETE_TEMPLATEWITHID",
      "GET_MESSAGINGTEMPLATEFROMMSG91",
    ],
  },
  {
    path: "/onboardingclient",
    name: "OnboardClient",
    icon: <TbUserPlus />,
    permissions: [
      "POST_ONBOARDEDCLIENTCREDENTIALS",
      "POST_GRAVITONDETAILS",
      "POST_CLIENT-ONBOARD",
    ],
  },
];
const Sidebar = () => {
  const { userInfo } = useSelector((state) => state.auth);
  const { clientId, isAdmin, permissions, role } = userInfo;
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  };
  const reduxdispatch = useDispatch();
  const {
    data: getAllClients,
    isLoading: loadingAllClients,
    error: errorAllClients,
  } = useGetAllClientsQuery({ clientname: clientId });
  console.log(getAllClients);
  const options = getAllClients?.map((item) => {
    return item.clientname;
  });

  const handleChange = (event) => {
    reduxdispatch(
      setCredentials({
        clientId: event.target.value,
        isAdmin,
        permissions,
        role,
      })
    );
  };
  const hasPermission = (requiredPermissions) => {
    return requiredPermissions.some((permission) =>
      permissions.includes(permission)
    );
  };
  return (
    <div
      className="sidebar-container "
      // style={{ width: isOpen ? "250px" : "55px" }}
    >
      <div
        className="sidebar"
        style={{
          width: isOpen ? "250px" : "55px",
          overflow: isOpen ? "none" : "hidden",
        }}
      >
        <div className="sidebar_section text-2xl">
          <div className="sidebar_bars" style={{ marginRight: "20px" }}>
            <FaBarsStaggered
              className="text-5xl cursor-pointer"
              onClick={toggle}
            />
          </div>
          <NavLink to={"/"} className="text-2xl text-black mr-3">
            Home
          </NavLink>
        </div>

        {
          <>
            {role !== 3 && (isAdmin || hasPermission(["GET_ALLCLIENTS"])) && (
              <NavLink className="sidebar_link">
                <div className="sidebar_link_icon text-red-600 text-5xl mt-8">
                  <RiAdminFill />
                </div>
                <DropDown
                  selectedOption={clientId}
                  options={options}
                  handleChange={handleChange}
                  type="Select Client"
                  addedClass={"md:w-auto px-4"}
                />
              </NavLink>
            )}

            {menuItem.map((item, index) =>
              isAdmin ? (
                <NavLink to={item.path} className="sidebar_link" key={index}>
                  <div className="sidebar_link_icon text-5xl">{item.icon}</div>
                  <div className="sidebar_link_text text-2xl">{item.name}</div>
                </NavLink>
              ) : (
                hasPermission(item.permissions) && (
                  <NavLink to={item.path} className="sidebar_link" key={index}>
                    <div className="sidebar_link_icon text-5xl">
                      {item.icon}
                    </div>
                    <div className="sidebar_link_text text-2xl">
                      {item.name}
                    </div>
                  </NavLink>
                )
              )
            )}
          </>
        }
      </div>
    </div>
  );
};

export default Sidebar;
