export const detailFieldStruct = [
  { name: "clientId", label: "Client ID" },
  { name: "clientName", label: "Client Name" },
  { name: "email", label: "Email" },
  { name: "registeredMobileNo", label: "Registered Mobile No" },
  { name: "activeMobileNo", label: "Active Mobile No" },
  { name: "panNo", label: "PAN No" },
  { name: "panDocument", label: "PAN Document" },
  { name: "gstNo", label: "GST No" },
  { name: "gstCertificate", label: "GST Certificate" },
  { name: "registrationCertifictae", label: "Registration Certificate" },
  { name: "otherDocumentOne", label: "Other Document 1" },
  { name: "otherDocumentTwo", label: "Other Document 2" },
];

export const paymentStruct1 = [
  { name: "vendor", label: "Vendor" },
  // { name: "params", label: "Params" },
];
export const headersFieldStruct1 = [
  { name: "xApiVersion", label: "x-api-version" },
  { name: "xClientId", label: "x-client-id" },
  { name: "xClientSecret", label: "x-client-secret" },
];
export const paymentStruct2 = [
  { name: "vendor2", label: "Vendor2" },
  // { name: "params2", label: "Params2" },
];
export const headersFieldStruct2 = [
  { name: "client_id", label: "client_id" },
  { name: "client_secret", label: "client_secret" },
  { name: "module_secret", label: "module_secret" },
  { name: "provider_secret", label: "provider_secret" },
];
export const settlementAccountFieldStruct = [
  { name: "name", label: "Name" },
  { name: "accountNumber", label: "Account Number" },
  { name: "accountIfsc", label: "Account IFSC" },
];
export const gravitonDetailsFieldStruct = [
  { name: "resourceUrl", label: "Resource URL" },
  { name: "tenantName", label: "Tenant Name" },
  { name: "orgLogo", label: "Org Logo" },
  { name: "orgReportHeader", label: "Org Report Header" },
  { name: "orgApplicationId", label: "Org Application ID" },
];

const getApplicationId = (clientId) => {
  const currentDate = new Date();
  const day = String(currentDate.getDate()).padStart(2, "0");
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const year = String(currentDate.getFullYear()).slice(-2);
  const dateClientId = `${day}${month}${year}${clientId}`;
  return dateClientId;
};

export const defaultValues = (clientId, clientNo, accountnumber) => {
  const body = {
    clientId: clientId,
    clientName: `${clientId} Work`,
    email: `${clientId}@gmail.com`,
    registeredMobileNo: "9415319980",
    activeMobileNo: "9636881500",
    panNo: "",
    panDocument: "",
    gstNo: "",
    gstCertificate: "",
    registrationCertifictae: "",
    otherDocumentOne: "",
    otherDocumentTwo: "",
    payments: [
      {
        vendor: "cashfree",
        headers: {
          "x-api-version": "2022-10-01",
          "x-client-id": "2052071faf77c096d02a09eb9f702502",
          "x-client-secret": "47f227d4c3f8c15d8e24ad17dd2c5f441cfc3f32",
        },
        params: {},
      },
      {
        vendor: "decentro",
        headers: {
          client_id: "kugelblitz_production",
          client_secret: "QKDuOpts3SDIddEq6eJOBTQBAp3SNh61",
          module_secret: "rwpQvd3lN1vzOKwHjlYEjzsbLFcRtG0o",
          provider_secret: "XE25ZIrR9WUvgqNLhgkSPp1Ql6uODLe",
        },
        params: {},
      },
    ],

    settlementAccount: [
      {
        name: "Demo5 Private Limited",
        accountNumber: accountnumber,
        accountIfsc: "CIUB1314121",
      },
    ],

    messagingVendors: [],
    gravitonDetails: {
      resourceUrl: "https://www.kugelblitz.in:8443/fineract-provider/api/v1",
      tenantName: `${clientId}`,
      orgLogo: `${clientId} url`,
      orgReportHeader: `${clientId} url`,
      orgApplicationId: `${getApplicationId(clientId)}${clientNo}`,
    },
  };
  return body;
};
