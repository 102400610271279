import { useState } from "react";
import {
  MdOutlineArrowBackIos,
  MdOutlineArrowForwardIos,
} from "react-icons/md";
import { BiLastPage, BiFirstPage } from "react-icons/bi";
import { BiSearch } from "react-icons/bi";

import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";

const Tablecomp = ({
  data,
  columns,
  onPageIndexChange,
  page,
  totalCount,
  limit,
}) => {
  const [sorting, setSorting] = useState([]);
  const [filtering, setFiltering] = useState("");
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      sorting: sorting,
      globalFilter: filtering,
    },
    onSortingChange: setSorting,
    onGlobalFilterChange: setFiltering,
  });
  const handlePageChange = (newIndex) => {
    if (onPageIndexChange) {
      onPageIndexChange(newIndex);
    }
  };
  return (
    <div className="tableandbuttons">
      <div className="navigationbuttonsandsearch">
        <div style={{ position: "relative" }}>
          <input
            type="text"
            placeholder="Search..."
            value={filtering}
            onChange={(e) => setFiltering(e.target.value)}
            style={{
              padding: "0.6rem",
              border: "2px solid #007aff",
              borderRadius: "5px",
              fontSize: "16px",
              outline: "none",
            }}
          />
          <BiSearch
            style={{
              position: "absolute",
              right: "10px",
              top: "50%",
              transform: "translateY(-50%)",
              fill: "#007aff",
              cursor: "pointer",
            }}
          />
        </div>
        <div></div>
        <div className="navigationbuttons">
          <BiFirstPage
            onClick={() => handlePageChange(1)}
            className={`MdOutlineArrowBackIos ${
              page === 1 ? "opacity-50 pointer-events-none" : ""
            }`}
          />
          <MdOutlineArrowBackIos
            onClick={() => handlePageChange(page - 1)}
            className={`MdOutlineArrowBackIos ${
              page === 1 ? "opacity-50 pointer-events-none" : ""
            }`}
          />
          <p className="buttonpara">{page}</p>
          <MdOutlineArrowForwardIos
            onClick={() => handlePageChange(page + 1)}
            className={`MdOutlineArrowForwardIos ${
              page * limit >= totalCount ? "opacity-50 pointer-events-none" : ""
            }`}
          />
          <BiLastPage
            onClick={() => handlePageChange(Math.ceil(totalCount / limit))}
            className={`MdOutlineArrowForwardIos ${
              page * limit >= totalCount ? "opacity-50 pointer-events-none" : ""
            }`}
          />
        </div>
      </div>
      <div className="tablecontainer">
        <table className="resptable">
          <thead>
            {table.getHeaderGroups().map((headerGroup, index) => (
              <tr key={index}>
                {headerGroup.headers.map((header, index) => (
                  <th
                    key={index}
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    {header.isPlaceholder ? null : (
                      <div>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {
                          { asc: "🔼", desc: "🔽" }[
                            header.column.getIsSorted() ?? null
                          ]
                        }
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row, index) => (
              <tr key={index}>
                {row.getVisibleCells().map((cell, index) => (
                  <td key={index}>
                    {console.log(
                      "check",
                      flexRender(cell.column.columnDef.cell, cell.getContext())
                    )}
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default Tablecomp;
