import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import React from "react";

const DateandTimePicker = ({ onDateTimeChange, defaultDate }) => {
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer
          components={["DateTimePicker", "DateTimePicker", "DateTimePicker"]}
        >
          <DemoItem
          // label={'"year"", "month", "day", "hours", "minutes" and "seconds"'}
          >
            <DateTimePicker
              onChange={onDateTimeChange}
              views={["year", "month", "day", "hours", "minutes", "seconds"]}
              value={defaultDate}
            />
          </DemoItem>
        </DemoContainer>
      </LocalizationProvider>
    </>
  );
};

export default DateandTimePicker;
