import React, { useEffect, useState } from "react";
import { useGetServiceUsageQuery } from "../slices/getqueries/getqueriesApi";
import { useSelector } from "react-redux";
import { Bar } from "react-chartjs-2";
import Chart from "chart.js/auto";
import DropDown from "./DropDown";
import Button from "./Button";
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const getMonth = (monthnumber) => {
  const month = months[monthnumber - 1];
  return month;
};
const getYears = () => {
  const currentYear = new Date().getFullYear();
  const startYear = 2020;
  const years = [];
  for (let year = startYear; year <= currentYear; year++) {
    years.push(year);
  }
  return years;
};

const BarChartComponent = () => {
  const { userInfo } = useSelector((state) => state.auth);
  const { clientId } = userInfo;
  const [totalServiceUsage, setserviceUsage] = useState(0);
  const [monthlyServiceUsage, setMonthlyServiceUsage] = useState([]);

  const [selectedYear, setSelectedYear] = useState(
    String(new Date().getFullYear())
  );
  const [selectedMonth, setSelectedMonth] = useState(
    getMonth(String(new Date().getMonth() + 1))
  );
  const [fromYear, setFromYear] = useState(String(new Date().getFullYear()));
  const [fromMonth, setFromMonth] = useState(
    getMonth(String(new Date().getMonth() + 1))
  );
  const { data, error, isLoading } = useGetServiceUsageQuery({
    clientId: clientId,
    year: fromYear,
    month: fromMonth,
  });
  useEffect(() => {
    if (data) {
      const resultsArray = data.results;
      if (
        data.results !== null &&
        resultsArray &&
        Array.isArray(resultsArray) &&
        resultsArray.length > 0
      ) {
        setMonthlyServiceUsage(resultsArray);
      }
      if (data.totalServiceCalled) {
        setserviceUsage(data.totalServiceCalled);
      }
    }
  }, [data]);

  const handleChangeYear = (e) => {
    setSelectedYear(e.target.value);
  };
  const handleChangeMonth = (e) => {
    setSelectedMonth(e.target.value);
  };
  const handleClick = () => {
    setFromYear(selectedYear);
    setFromMonth(selectedMonth);
  };
  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <div className="w-full h-auto">
      <div className="">
        <div className="grid grid-cols-1 shadow-xl rounded-2xl bg-gray-50 md:grid-cols-2 gap-4">
          <div className=" m-4 p-1">
            <div className="flex justify-around items-center mx-3 px-4">
              <div>
                <h1 className="text-3xl mt-10 text-gray-500 font-semibold">
                  Total Count
                </h1>
                {data.totalServiceCalled > 0 ? (
                  <span className="text-5xl text-gray-400 font-semibold">
                    {totalServiceUsage}
                  </span>
                ) : (
                  <span className="text-xl text-red-500">
                    No data to display
                  </span>
                )}
              </div>
              <div className="flex flex-col justify-center md:flex-row md:items-center lg:flex-row lg:items-center gap-2 mt-4 md:mt-0">
                <div className="flex items-center">
                  <DropDown
                    selectedOption={selectedYear}
                    options={getYears()}
                    handleChange={handleChangeYear}
                    type="Select Year"
                  />
                  <DropDown
                    selectedOption={selectedMonth}
                    options={months}
                    handleChange={handleChangeMonth}
                    type="Select Month"
                  />
                </div>
                <div className="flex justify-end">
                  <Button
                    name="Find"
                    onClick={handleClick}
                    classNames="bg-blue-500 text-white px-3 py-1 hover:bg-blue-600 hover:cursor-pointer mt-10"
                  />
                </div>
              </div>
            </div>

            {data.results !== null ? (
              <div
                className="flex justify-center items-center m-4 p-4 "
                style={{
                  height: "350px",
                }}
              >
                <Bar
                  data={{
                    labels: monthlyServiceUsage.map((mc) => mc.moduleName),
                    datasets: [
                      {
                        label: "Services Used",
                        data: monthlyServiceUsage.map((mc) => mc.usage),
                        backgroundColor: "rgba(99, 102, 241, 1)",
                        borderWidth: 1,
                        barPercentage: 0.5,
                      },
                    ],
                  }}
                  options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                      x: {
                        grid: {
                          display: false,
                        },
                        ticks: {
                          font: {
                            size: 12,
                          },
                        },
                      },
                      y: {
                        grid: {
                          display: false,
                        },
                        ticks: {
                          font: {
                            size: 12,
                          },
                        },
                      },
                    },
                    plugins: {
                      legend: {
                        display: false,
                      },
                    },
                  }}
                />
              </div>
            ) : (
              <div className="flex justify-center items-center">
                {/* <span className="text-2xl text-red-500">
                    No data to display
                  </span> */}
              </div>
            )}
          </div>
          <div className="flex flex-col bg-gray-50 m-4  p-4 justify-start gap-2">
            {data.results !== null ? (
              <h1 className="text-3xl text-center text-gray-600 gap-2 font-bold break-words">
                Monthly services used
              </h1>
            ) : (
              <div className="flex justify-center items-center">
                <span className="text-2xl mt-10 text-red-500">
                  No data to display
                </span>
              </div>
            )}
            {data.results !== null ? (
              <div className="grid w-full gap-2 p-4">
                <div className="grid grid-cols-2 justify-around items-center bg-gray-100 rounded-xl mb-4 p-2 gap-4">
                  <span className="text-2xl text-gray-600 font-semibold break-words text-center">
                    Service
                  </span>
                  <span className="text-2xl text-gray-600 font-semibold break-words text-center">
                    Usage
                  </span>
                  {/* <span className="text-xl text-gray-600 font-semibold break-words text-center">
                    Charge
                  </span> */}
                </div>

                {monthlyServiceUsage.map((mc) => (
                  <div key={mc.moduleName}>
                    <div className="grid grid-cols-2 p-1 gap-2 text-gray-500">
                      <span className="text-xl text-gray-500 font-semibold break-words text-center">
                        {mc.moduleName}
                      </span>
                      <span className="text-xl text-gray-500 font-semibold break-words text-center">
                        {mc.usage}
                      </span>
                      {/* <span className="text-xl text-gray-500 font-semibold break-words text-center">
                        {mc.sumchargepercall}
                      </span> */}
                    </div>
                    <hr className="h-px my-4 bg-gray-200 border-0" />
                  </div>
                ))}
              </div>
            ) : (
              <div className="flex justify-center items-center">
                {/* <span className="text-2xl text-red-500">
                  No data to display
                </span> */}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BarChartComponent;
