import * as Yup from "yup";

export const rechargeSchema = Yup.object({
  amount: Yup.number().min(1).required("Please enter the required amount."),
  otp: Yup.number()
    .typeError("OTP must be a number")
    .integer("OTP must be an integer")
    .min(100000, "OTP must be exactly 6 digits")
    .max(999999, "OTP must be exactly 6 digits")
    .required("OTP is required"),
});

export const cibilSchema = Yup.object({
  username: Yup.string()
    .required("Username is required")
    .min(3, "Username must be at least 3 characters")
    .max(20, "Username must be at most 20 characters"),
  password: Yup.string()
    .required("Password is required")
    .min(5, "Password must be at least 5 characters")
    .matches(
      /^(?=.*[a-z])(?=.*\d)[a-zA-Z\d@$!%*?&]{6,}$/,
      "Password must contain at least one lowercase letter, and one number"
    ),
});
export const cibilLayer7Schema = Yup.object({
  apiKey: Yup.string().required("Api Key is required"),
  memberReferenceId: Yup.string().required("Member Reference ID is required"),
  customerReferenceId: Yup.string().required(
    "Customer Reference ID is required"
  ),
  password: Yup.string()
    .required("Password is required")
    .min(5, "Password must be at least 5 characters")
    .matches(
      /^(?=.*[a-z])(?=.*\d)[a-zA-Z\d@$!%*?&]{6,}$/,
      "Password must contain at least one lowercase letter, and one number"
    ),
  serviceCode: Yup.string().required("ServiceCode is required"),
  enquiryMemberUserID: Yup.string().required("EnquiryMemberUserId is required"),
  enquiryPassword: Yup.string().required("EnquiryPassword is required"),

  p12Certificate: Yup.mixed().required("p12Certificate is required"),
});

export const messageTemplateSchema = Yup.object({
  templateId: Yup.string().required("templateId is required"),
});

export const onboardClientSchema = Yup.object({
  clientId: Yup.string().required("Client ID is required"),
  clientName: Yup.string().required("Client Name is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  registeredMobileNo: Yup.string().required("Registered Mobile No is required"),
  activeMobileNo: Yup.string().required("Active Mobile No is required"),
  panNo: Yup.string().required("PAN No is required"),
  panDocument: Yup.string().required("PAN Document is required"),
  gstNo: Yup.string().required("GST No is required"),
  gstCertificate: Yup.string().required("GST Certificate is required"),
  registrationCertifictae: Yup.string().required(
    "Registration Certificate is required"
  ),
  vendor: Yup.string().required("Vendor is required"),
  xApiVersion: Yup.string().required("x-api-version is required"),
  xClientId: Yup.string().required("x-client-id is required"),
  xClientSecret: Yup.string().required("x-client-secret is required"),
  vendor2: Yup.string().required("Vendor2 is required"),
  client_id: Yup.string().required("client_id is required"),
  client_secret: Yup.string().required("client_secret is required"),
  module_secret: Yup.string().required("module_secret is required"),
  provider_secret: Yup.string().required("provider_secret is required"),
  name: Yup.string().required("Name is required"),
  accountNumber: Yup.string().required("Account Number is required"),
  accountIfsc: Yup.string().required("Account IFSC is required"),
  resourceUrl: Yup.string().required("Resource URL is required"),
  tenantName: Yup.string().required("Tenant Name is required"),
  orgLogo: Yup.string().required("Org Logo is required"),
  orgReportHeader: Yup.string().required("Org Report Header is required"),
  orgApplicationId: Yup.string().required("Org Application ID is required"),
});

export const clientIdSchemaOnboarding = Yup.object({
  clientId: Yup.string().required("Client ID is required"),
});
