import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { CiSearch } from "react-icons/ci";
import { IoIosNotificationsOutline } from "react-icons/io";
import { useSelector, useDispatch } from "react-redux";
import { useLogoutMutation } from "../slices/postqueries/userqueriesApi";
import { setAllNull } from "../slices/DateAndTimeslice";
import { logout } from "../slices/authslice";
import { BsChevronDown } from "react-icons/bs";
import Button from "./Button";
import { toast } from "react-toastify";
import { GiHamburgerMenu } from "react-icons/gi";

const Header = () => {
  const { userInfo } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(true);
  const [isMenuIconOpen, setIsMenuIconOpen] = useState(false);
  const [logoutApiCall] = useLogoutMutation();

  const logoutHandler = async () => {
    try {
      await logoutApiCall().unwrap();
      dispatch(logout());
      dispatch(setAllNull());
      navigate("/login");
      toast.success("successfully logged out!");
    } catch (err) {
      console.log(err);
    }
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleNavbarMenuIcon = () => {
    setIsMenuIconOpen(!isMenuIconOpen);
  };

  return (
    <>
      {userInfo ? (
        <header className={userInfo ? "header-afterlogin" : "header"}>
          <span className="header__icon">SUPER USER</span>
          <form action="#" className="search">
            <input
              type="text"
              className="search__input"
              placeholder="Search lead,enquiry, loans..."
            />
            <button className="search__button">
              <CiSearch style={{ fontSize: "2rem" }} />
            </button>
          </form>
          <nav className="rightnav">
            <div className="rightnav-1">
              <IoIosNotificationsOutline style={{ fontSize: "2.4rem" }} />
            </div>
            <div className="dropdown-container">
              <div
                className="dropdown-header flex justify-center items-center gap-x-3"
                onClick={toggleDropdown}
              >
                <span className="rightnav-2__para">{userInfo.clientId}</span>
                <BsChevronDown style={{ fontSize: "1rem" }} />
              </div>
              {isOpen && (
                <div className="dropdown-content">
                  <ul className="flex items-center flex-col">
                    <li>
                      <Button
                        name="Logout"
                        onClick={logoutHandler}
                        classNames="bg-primary hover:bg-blue-500 text-white"
                      />
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </nav>
          <nav className="rightnav-menu-icon">
            <button onClick={handleNavbarMenuIcon} className="py-2">
              <GiHamburgerMenu className="text-5xl cursor-pointer" />
            </button>
          </nav>
          <div
            style={{
              height: isMenuIconOpen ? "18rem" : "0rem",
            }}
            className={`transition-div ${
              isMenuIconOpen ? "open" : ""
            } md:hidden lg:hidden w-full flex flex-col items-center justify-center`}
          >
            <div className="py-1 w-full">
              <div className="w-full flex justify-center">
                <form
                  action="#"
                  className="rightnav-menu-icon_form flex justify-around items-center m-1 p-1 rounded-full"
                >
                  <input
                    type="text"
                    className="rightnav-menu-icon__input"
                    placeholder="Search lead,enquiry, loans..."
                  />
                  <button className="rightnav-menu-icon__button">
                    <CiSearch style={{ fontSize: "2rem" }} />
                  </button>
                </form>
              </div>
              <div className="flex justify-center items-center m-3 p-2 cursor-pointer hover:text-blue-500">
                <IoIosNotificationsOutline style={{ fontSize: "2.4rem" }} />
                <span className="text-xl">Notifications</span>
              </div>

              {/*  */}
              <div className="flex w-full justify-center">
                <Button
                  name="Logout"
                  onClick={logoutHandler}
                  classNames="bg-primary hover:bg-blue-500 text-white"
                />
              </div>
              {/*  */}
            </div>
          </div>
        </header>
      ) : null}
    </>
  );
};

export default Header;
