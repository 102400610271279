import React from "react";

const Message = ({ variant, children }) => {
  const style = {
    backgroundColor: variant === "info" ? "#d1ecf1" : variant === "success" ? "#d4edda" : "#f8d7da",
    padding: "10px",
    border: "1px solid #ccc",
    borderRadius: "4px",
    marginBottom: "10px",
  };

  return (
    <div style={style}>
      {children}
    </div>
  );
};

Message.defaultProps = {
  variant: "info",
};

export default Message;