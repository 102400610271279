import React from "react";
import Button from "../../components/Button";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";

const OnboardingScreen = () => {
  const buttons = [
    {
      name: "Create Message Template",
      href: "/create-template",
      permission: [
        "POST_CREATEMESSAGINGTEMPLATE",
        "GET_MESSAGINGTEMPLATEFROMMSG91",
      ],
    },
    {
      name: "See Templates",
      href: "/allTemplates",
      permission: ["GET_TEMPLATESWITHLIMIT", "DELETE_TEMPLATEWITHID"],
    },
  ];

  const { userInfo } = useSelector((state) => state.auth);
  const { permissions } = userInfo;
  const hasPermission = (requiredPermissions) => {
    return requiredPermissions.some((permission) =>
      permissions.includes(permission)
    );
  };
  return (
    <div className="flex flex-col items-center w-full h-full">
      <h2 className="text-4xl font-bold mb-8 text-center">
        Messaging Services
      </h2>

      <div className="rounded-xl shadow-lg p-5 flex flex-col items-center w-full md:w-1/2">
        {buttons.map((button, index) => (
          <NavLink
            className={`w-full flex items-center justify-center`}
            to={
              button.permission && !hasPermission(button.permission)
                ? "#"
                : button.href
            }
            key={index}
          >
            <Button
              name={button.name}
              classNames={`rounded-full text-lg bg-indigo-500 shadow-md shadow-indigo-500/30 w-full md:w-1/2 mb-10 text-white px-3 py-6 hover:text-indigo-500 hover:bg-white hover:shadow-lg hover:shadow-indigo-600/30 hover:border hover:border-indigo-500 ${
                button.permission && !hasPermission(button.permission)
                  ? "opacity-50 hover:text-white hover:bg-indigo-500 hover:cursor-not-allowed"
                  : ""
              }`}
              disabled={button.permission && hasPermission(button.permission)}
              style={{
                cursor:
                  button.permission && !hasPermission(button.permission)
                    ? "not-allowed"
                    : "pointer",
              }}
            />
          </NavLink>
        ))}
      </div>
    </div>
  );
};

export default OnboardingScreen;
