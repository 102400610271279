import React, { useEffect, useState } from "react";
import { IoEyeOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import Modal from "./Modal";
import { useGetServiceResponseQuery } from "../slices/getqueries/getqueriesApi";

const Viewreport = ({ requestId }) => {
  const { userInfo } = useSelector((state) => state.auth);
  const { clientId } = userInfo;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [response, setResponse] = useState(null);

  const {
    data: currentResponseData,
    isFetching,
    error: errorcurrentplandata,
  } = useGetServiceResponseQuery(
    {
      requestId: requestId,
      clientname: clientId,
    },
    { skip: !isModalOpen }
  );

  const handleViewClick = (event) => {
    console.log(response);
    event.stopPropagation();
    setIsModalOpen(true);
  };
  useEffect(() => {
    if (currentResponseData) {
      setResponse(JSON.stringify(currentResponseData, null, 2));
    }
  }, [currentResponseData]);
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <button
        onClick={handleViewClick}
        className="py-2 px-2 text-primary w-full border border-blue-500 flex gap-2 justify-center items-center"
      >
        <IoEyeOutline className="icon" />
        View
      </button>
      {!isFetching && (
        <Modal
          response={response}
          isOpen={isModalOpen}
          onClose={handleCloseModal}
        />
      )}
    </div>
  );
};

export default Viewreport;
