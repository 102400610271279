// import { Container } from "react-bootstrap";
import { Outlet } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { ToastContainer, Zoom } from "react-toastify";
// import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";

import "../src/styles/main.scss";
import Sidebar from "./components/Sidebar";

function App() {
  const { userInfo } = useSelector((state) => state.auth);
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        transition={Zoom}
      />
      <div
        className={
          userInfo ? "containerpro-afterlogin md:p-0" : "containerpro md:p-0"
        }
      >
        {/* <div className="containerpro md:p-0"> */}
        <Header />
        {userInfo && <Sidebar />}
        <div
          className={userInfo ? "midcontainer-afterlogin p-6" : "midcontainer"}
        >
          <Outlet />
        </div>
        <Footer />
      </div>
    </>
  );
}

export default App;
