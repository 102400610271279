import {
  BASE_URL,
  CLIENT_ONBOARD,
  CREATECIBIL_CREDENTIAL,
  CREATETEMPLATE_URL,
  GRAVITON_DETAILS,
  ONBOARDED_CLIENT_CREDENTIALS,
  UPDATECIBIL_CREDENTIAL,
} from "../../constants";
import { apiSlice } from "./../apislice";

export const rechargeApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    otp: builder.mutation({
      query: ({ adminphone, client, amount }) => ({
        url: `callreport/sendOtpPaymentDashboard?mobile=${adminphone}&otp_length=6&otp_expiry=3`,
        method: "POST",
        body: {
          clientandamount: `${client}_${amount}`,
          additional_details: {
            clientname: client,
            amount: amount,
          },
        },
        withCredentials: true,
        credentials: "include",
      }),
    }),
    verifyOtp: builder.mutation({
      query: ({ adminphone, client, otp, amount, transactionid }) => ({
        url: `callreport/verifyOtpPaymentDashboard?mobile=${adminphone}&otp=${otp}&otp_expiry=3`,
        method: "POST",
        body: {
          transactionid: transactionid,
          amountCredited: amount,
          clientName: client,
        },
        withCredentials: true,
        credentials: "include",
      }),
    }),
    createCredentials: builder.mutation({
      query: ({ credentials, product, clientId }) => {
        const headers = new Headers();
        const formData = new FormData();
        for (const key in credentials) {
          formData.append(key, credentials[key]);
        }
        headers["Content-Type"] = `multipart/form-data`;
        let body;
        if (product === "layer7") {
          body = formData;
        } else {
          const jsonBody = {};
          for (const [key, value] of Object.entries(credentials)) {
            jsonBody[key] = value;
          }
          headers["Content-Type"] = "application/json";
          body = JSON.stringify(jsonBody);
        }
        return {
          url: `callreport/createCredentials/${clientId}/${product}`,
          method: "POST",
          body: body,
          headers: headers,
          withCredentials: true,
          credentials: "include",
        };
      },
    }),

    updateCredentials: builder.mutation({
      query: ({ credentials, product, clientId }) => {
        const headers = new Headers();
        const formData = new FormData();
        for (const key in credentials) {
          formData.append(key, credentials[key]);
        }
        headers["Content-Type"] = `multipart/form-data`;
        let body;
        if (product === "layer7") {
          body = formData;
        } else {
          const jsonBody = {};
          for (const [key, value] of Object.entries(credentials)) {
            jsonBody[key] = value;
          }
          headers["Content-Type"] = "application/json";
          body = JSON.stringify(jsonBody);
        }
        return {
          url: `callreport/updateCredentials/${clientId}/${product}`,
          method: "POST",
          body: body,
          headers: headers,
          withCredentials: true,
          credentials: "include",
        };
      },
    }),
    createMessageTemplate: builder.mutation({
      query: ({ body, clientname }) => ({
        url: `${BASE_URL}${CREATETEMPLATE_URL}?clientname=${clientname}`,
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
        credentials: "include",
      }),
    }),
    onboardClient: builder.mutation({
      query: ({ reqBody, clientId, clientSecret }) => ({
        url: `${BASE_URL}${CLIENT_ONBOARD}`,
        method: "POST",
        body: JSON.stringify(reqBody),
        headers: {
          "Content-Type": "application/json",
          client_id: clientId,
          client_secret: clientSecret,
          admin_secret: "5e3adf23ffd932065eed5118458cad5b",
        },
        withCredentials: true,
      }),
    }),
    onboardedClientCredentials: builder.mutation({
      query: ({ clientId }) => ({
        url: `${BASE_URL}${ONBOARDED_CLIENT_CREDENTIALS}/${clientId}`,
        method: "POST",
        withCredentials: true,
        credentials: "include",
      }),
    }),
    gravitonDetails: builder.mutation({
      query: ({ clientId }) => ({
        url: `${BASE_URL}${GRAVITON_DETAILS}/${clientId}`,
        method: "POST",
        withCredentials: true,
        credentials: "include",
      }),
    }),
  }),
});

export const {
  useOtpMutation,
  useVerifyOtpMutation,
  useCreateCredentialsMutation,
  useUpdateCredentialsMutation,
  useCreateMessageTemplateMutation,
  useOnboardClientMutation,
  useOnboardedClientCredentialsMutation,
  useGravitonDetailsMutation,
} = rechargeApiSlice;
