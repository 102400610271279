import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { useGetTransactionHistoryQuery } from "../slices/getqueries/getqueriesApi";
import Loader from "../components/Loader";
import { Message } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import Tablecomp from "../components/table/tablecomp";

const TransactionHistory = () => {
  const { userInfo } = useSelector((state) => state.auth);
  const { clientId } = userInfo;

  const {
    data: transactionHistories,
    isLoading,
    error: errorTransactionhistoryData,
  } = useGetTransactionHistoryQuery({ clientname: clientId });
  console.log(transactionHistories);

  const columns = [
    {
      header: "TransactionId",
      accessorKey: "transactionid",
      footer: "TransactionId",
    },
    {
      header: "AmountCredit",
      accessorKey: "amountcredited",
    },
    {
      header: "PaymentTypes",
      accessorKey: "paymenttype",
      footer: "PaymentTypes",
    },
    {
      header: "TransactionDate",
      accessorKey: "transactiondate",
      footer: "TransactionDate",
      cell: (info) => {
        const timestamp = info.getValue();
        const dateComponent = timestamp.split(" ")[0];
        return dateComponent;
      },
    },
    {
      header: "CreditBalance",
      accessorKey: "creditbalance",
      footer: "CreditBalance",
    },
  ];
  let transactionData = useMemo(
    () => transactionHistories,
    [transactionHistories]
  );

  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : errorTransactionhistoryData ? (
        <Message variant="danger">
          {errorTransactionhistoryData?.data?.message ||
            errorTransactionhistoryData.error}
        </Message>
      ) : (
        <div className="reportcontainer">
          <div className="col-span-4 md:col-span-5 mr-8 justify-self-center self-center">
            <button
              className="w-full flex items-center justify-center px-5 py-4 text-md transition-colors duration-200 bg-blue-500 text-white border rounded-lg gap-x-2 sm:w-auto bg-primary hover:bg-blue-500 text-white font-bold"
              onClick={handleGoBack}
            >
              <svg
                class="w-5 h-5 rtl:rotate-180"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18"
                />
              </svg>
              <span>Go back</span>
            </button>
          </div>

          <div className="report-table-container">
            <Tablecomp
              data={transactionData ? transactionData : ""}
              columns={columns}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default TransactionHistory;
