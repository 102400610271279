import React, { useState, useEffect } from "react";
import DateandTimePicker from "./DateandTimePicker";
import dayjs from "dayjs";
import Button from "./Button";
import DropDown from "./DropDown";
import { changeDate, getDateWithTimeEndpoint } from "../utils/utilityFunctions";
import {
  setSelectedDatefrom,
  setSelectedDateto,
  setSelectedOption,
} from "../slices/DateAndTimeslice";
import { useDispatch, useSelector } from "react-redux";

const SecondHeader = ({ onChildClick, onChangeGroupdate }) => {
  const { userInfo } = useSelector((state) => state.auth);
  const { clientId } = userInfo;
  const options = [
    "Today",
    "LastTwoDays",
    "LastThreeDays",
    "LastWeek",
    "LastMonth",
    "LastTwoMonth",
  ];
  const [activeButton, setActiveButton] = useState("All");
  const dispatch = useDispatch();
  const { selectedDatefrom, selectedDateto, selectedOption } = useSelector(
    (state) => state.dateandtime
  );
  const buttonNames = [
    "All",
    "KYC",
    "Communication",
    "Payments",
    "Credit",
    "BankStatement",
  ];

  useEffect(() => {
    onChildClick(0);
  }, []);

  useEffect(() => {
    dateQuery();
  }, []);

  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
    if (buttonName === "All") {
      onChildClick(0);
    } else if (buttonName === "Payments") {
      onChildClick(1);
    } else if (buttonName === "Communication") {
      onChildClick(2);
    } else if (buttonName === "BankStatement") {
      onChildClick(3);
    } else if (buttonName === "Credit") {
      onChildClick(4);
    } else if (buttonName === "KYC") {
      onChildClick(5);
    }
  };

  const handleOptionChange = (days) => {
    const { endpoint, fromdate, todate } = getDateWithTimeEndpoint(days);
    dispatch(setSelectedDatefrom(fromdate));
    dispatch(setSelectedDateto(todate));
    onChangeGroupdate(endpoint);
  };

  const handleChange = (event) => {
    dispatch(setSelectedOption(event.target.value));

    switch (event.target.value) {
      case "Today":
        handleOptionChange(1);
        break;
      case "LastTwoDays":
        handleOptionChange(2);
        break;
      case "LastThreeDays":
        handleOptionChange(3);
        break;
      case "LastWeek":
        handleOptionChange(7);
        break;
      case "LastMonth":
        handleOptionChange(30);
        break;
      case "LastTwoMonth":
        handleOptionChange(60);
        break;
      default:
        break;
    }
  };

  const handleDateTimeChangefrom = (date) => {
    dispatch(setSelectedDatefrom(changeDate(date)));
  };

  const handleDateTimeChangeto = (date) => {
    dispatch(setSelectedDateto(changeDate(date)));
  };

  const dateQuery = () => {
    const formateddatefrom = changeDate(selectedDatefrom);
    const formateddateto = changeDate(selectedDateto);
    const endpoint = `from=${formateddatefrom}&to=${formateddateto}`;

    onChangeGroupdate(endpoint);
  };

  return (
    <div className="secondheader">
      <div className="flex flex-col items-start md:flex-row pb-4 justify-between md:items-end ">
        <h1 className="text-4xl mb-5">Hi {clientId}!</h1>
        <div className="date flex flex-col md:flex-row items-center space-x-6 w-full md:w-auto">
          <DropDown
            selectedOption={selectedOption}
            options={options}
            handleChange={handleChange}
            type="Select Time"
            addedClass={"w-full md:w-auto"}
          />
          <div className="date-fromdate w-full md:w-auto">
            <h2 className="text-xl">From</h2>
            <DateandTimePicker
              onDateTimeChange={handleDateTimeChangefrom}
              defaultDate={dayjs(selectedDatefrom)}
            />
          </div>
          <div className="date-todate w-full md:w-auto">
            <h2 className="text-xl">To</h2>
            <DateandTimePicker
              onDateTimeChange={handleDateTimeChangeto}
              defaultDate={dayjs(selectedDateto)}
            />
          </div>
          <div className="mt-10">
            <Button
              name="Find"
              onClick={dateQuery}
              classNames="bg-primary hover:bg-blue-500 text-white"
            />
          </div>
        </div>
      </div>
      <div className="flex space-x-4 md:space-x-6 gap-y-8 md:gap-0 flex-wrap">
        {buttonNames.map((buttonName) => (
          <button
            key={buttonName}
            className={`bg-transparent hover:bg-blue-500 text-[#007AFF] font-semibold hover:text-white py-2 px-4 border border-blue-300 hover:border-transparent rounded header-button ${
              activeButton === buttonName ? "headerbuttoncolor" : ""
            }`}
            onClick={() => handleButtonClick(buttonName)}
          >
            {buttonName}
          </button>
        ))}
      </div>
    </div>
  );
};

export default SecondHeader;
