// import React from "react";
// import { useFormik } from "formik";
// import DropDown from "../components/DropDown";
// import Button from "../components/Button";
// import { messageTemplateSchema } from "../formSchemas";
// import { useCreateMessageTemplateMutation } from "../slices/postqueries/postqueriesApi";
// import { toast } from "react-toastify";
// import { useSelector } from "react-redux";

// const CreateMessageTemplate = () => {
//   const initialValues = {
//     templateId: "",
//     keyValuePairs: [{ key: "", valueType: "" }],
//     sampleTemplate: "",
//   };

//   const [createMessageTemplate] = useCreateMessageTemplateMutation();
//   const { userInfo } = useSelector((state) => state.auth);
//   const { clientId } = userInfo;
//   const formik = useFormik({
//     initialValues: initialValues,
//     validationSchema: messageTemplateSchema,
//     onSubmit: async (values, action) => {
//       function transformObject(obj) {
//         const result = {};
//         const key = obj.templateId;
//         const keyValuePairs = obj.keyValuePairs;

//         result[key] = keyValuePairs.reduce((acc, pair) => {
//           acc[pair.key] = pair.valueType;
//           return acc;
//         }, {});

//         return result;
//       }
//       try {
//         console.log("Form values:", values);
//         const template = transformObject(values);
//         template["sampleTemplate"] = values.sampleTemplate;
//         console.log("Template : ", template);
//         const res = await createMessageTemplate({
//           template,
//           clientname: clientId,
//         }).unwrap();
//         console.log("Response:", res);
//         if (res.status === "success") {
//           toast.success("Successfully Created message template!", {
//             autoClose: 2000,
//           });
//         } else {
//           toast.error("Error creating template");
//         }
//         action.resetForm();
//       } catch (error) {
//         console.log(error?.data?.message);
//         toast.error(error?.data?.message || error.data);
//       }
//     },
//   });

//   const handleAddField = (e) => {
//     e.preventDefault();
//     formik.setValues({
//       ...formik.values,
//       keyValuePairs: [
//         ...formik.values.keyValuePairs,
//         { key: "", valueType: "" },
//       ],
//     });
//   };

//   const handleRemoveField = (index, e) => {
//     e.preventDefault();
//     formik.setValues({
//       ...formik.values,
//       keyValuePairs: formik.values.keyValuePairs.filter((_, i) => i !== index),
//     });
//   };

//   return (
//     <div className="w-full h-full m-5">
//       <div className="flex flex-col items-center">
//         <h1 className="text-3xl font-bold mb-4">Create Message Template</h1>
//         <div className="w-1/2 h-full p-4 rounded-xl border border-gray-1">
//           <form onSubmit={formik.handleSubmit} className="space-y-4">
//             <div>
//               <label className="block text-xl text-gray-600 font-semibold mb-2">
//                 Template ID:
//               </label>
//               <input
//                 type="text"
//                 name="templateId"
//                 placeholder="Template ID"
//                 value={formik.values.templateId}
//                 onChange={formik.handleChange}
//                 className="w-full px-4 py-4 border bg-gray-100 border-gray-300 rounded-md text-2xl focus:outline-none"
//               />
//               {formik.touched.templateId && formik.errors.templateId && (
//                 <div className="text-red-500 mb-5 error text-md">
//                   {formik.errors.templateId}
//                 </div>
//               )}
//             </div>
//             <label className="block text-xl text-gray-600 font-semibold mb-0">
//               Fields:
//             </label>
//             {formik.values.keyValuePairs.map((pair, index) => (
//               <div
//                 key={index}
//                 className="flex mt-0 justify-center items-center space-x-2"
//               >
//                 <input
//                   type="text"
//                   placeholder="Key"
//                   value={pair.key}
//                   onChange={(e) =>
//                     formik.setFieldValue(
//                       `keyValuePairs.${index}.key`,
//                       e.target.value
//                     )
//                   }
//                   className="w-1/2 mt-10 px-4 border bg-gray-100 border-gray-300 rounded-md text-center text-2xl focus:outline-none"
//                   style={{
//                     lineHeight: "4.5rem", // Adjust as needed
//                     textAlign: "center",
//                   }}
//                 />

//                 <DropDown
//                   selectedOption={pair.valueType}
//                   options={["string", "int", "float"]}
//                   handleChange={(e) => {
//                     formik.setFieldValue(
//                       `keyValuePairs[${index}].valueType`,
//                       e.target.value
//                     );
//                   }}
//                   type="Value Type"
//                   addedClass="w-1/2 rounded-md mt-0 text-xl"
//                 />
//                 <Button
//                   name="Remove"
//                   onClick={(e) => handleRemoveField(index, e)}
//                   classNames="px-2 mt-10 py-2 bg-red-500 text-white rounded-md"
//                 />
//               </div>
//             ))}
//             <Button
//               name="Add new field"
//               onClick={handleAddField}
//               classNames="px-4 py-2 bg-blue-500 text-white rounded-md"
//             />
//             <label className="block text-xl text-gray-600 font-semibold mb-0">
//               Sample Template:
//             </label>
//             <textarea
//               // type="textarea"
//               name="sampleTemplate"
//               placeholder={`Example: Dear ##field1## , your cibil till ##field2##.....many more.\nDisclaimer: All fields should be match with your template fields above.`}
//               value={formik.values.sampleTemplate}
//               onChange={formik.handleChange}
//               className="w-full px-4 h-64 py-4 text-left align-top bg-gray-100 border border-gray-300 rounded-md text-2xl focus:outline-none"
//               style={{ resize: "none" }}
//             />
//             {formik.touched.sampleTemplate && formik.errors.sampleTemplate && (
//               <div className="text-red-500 mb-5 error text-md">
//                 {formik.errors.sampleTemplate}
//               </div>
//             )}
//             <div className="flex justify-center">
//               <Button
//                 name="Create Template"
//                 type="submit"
//                 classNames="w-1/3 px-4 py-2 bg-green-500 text-white rounded-md "
//               />
//             </div>
//           </form>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default CreateMessageTemplate;

import React, { useEffect, useState } from "react";
import { messageTemplateSchema } from "../../formSchemas";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { useGetMessageTemplateMsg91Query } from "../../slices/getqueries/getqueriesApi";
import { useSelector } from "react-redux";
import Button from "../../components/Button";
import { useCreateMessageTemplateMutation } from "../../slices/postqueries/postqueriesApi";
import { useNavigate } from "react-router-dom";

const extractKeys = (template) => {
  const regex = /##(.*?)##/g;
  const keys = [
    ...new Set(template.match(regex).map((key) => key.slice(2, -2))),
  ];

  const result = {};
  keys.forEach((key) => {
    result[key] = "string";
  });

  return result;
};
const CreateMessageTemplate = () => {
  const navigate = useNavigate();

  const initialValues = {
    templateId: "",
  };
  const [fields, setFields] = useState(null);
  const [sampleTemplate, setSampleTemplate] = useState(null);
  const [template, setTemplate] = useState("");

  const handleMessageTemplate = async (templateId) => {
    try {
      const temp = templateId;
      if (temp === "") {
        toast.error("Please provide template Id");
      } else if (temp.length === 24) {
        setFields(null);
        setSampleTemplate(null);
        setTemplate(temp);
      } else {
        toast.error("Template id should have length exactly 24");
        setTemplate("");
      }
    } catch (error) {
      console.log(error?.data?.message);
      toast.error(error?.data?.message || error.data);
    }
  };
  const { userInfo } = useSelector((state) => state.auth);
  const { clientId } = userInfo;
  const { data, error, isLoading } = useGetMessageTemplateMsg91Query(
    { templateId: template, clientId: clientId },
    {
      skip: !template,
    }
  );
  useEffect(() => {
    // Checking if both data and template are truthy
    if (data && template && Array.isArray(data) && data.length > 0) {
      console.log(data);
      const dataArray = data[0];
      if (dataArray && dataArray.template_data) {
        const res = {
          fields: extractKeys(dataArray.template_data),
          sampleTemplate: dataArray.template_data,
        };
        setFields(res.fields);
        setSampleTemplate(res.sampleTemplate);
      }
    } else {
      // Only show error if data or template are falsy
      if (template !== "") {
        toast.error("No template found for this templateId", {
          autoClose: 2000,
        });
      }
      setFields(null);
      setSampleTemplate(null);
    }
  }, [data]);

  const [createTemplate] = useCreateMessageTemplateMutation();

  const formik = useFormik({
    initialValues: initialValues,
    vallidationSchema: messageTemplateSchema,
    onSubmit: async (values, action) => {
      try {
        console.log("Template : ", template);
        const body = {
          [values.templateId]: fields,
          sampleTemplate: sampleTemplate,
        };
        console.log(body);
        const res = await createTemplate({
          body,
          clientname: clientId,
        }).unwrap();
        console.log("Response:", res);
        if (res.status === "success") {
          toast.success("Successfully Created message template!", {
            autoClose: 2000,
          });
        } else {
          toast.error("Error creating template");
        }
        setTemplate("");
        action.resetForm();
      } catch (error) {
        console.log(error?.data?.message);
        toast.error(error?.data?.message || error.data);
      }
    },
  });
  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;
  const handleGoBack = () => {
    navigate(-1);
  };
  return (
    <div className="w-full h-full">
      <Button
        name="< Go back"
        onClick={handleGoBack}
        classNames="bg-blue-500 text-white px-3 py-1 hover:bg-blue-600 hover:cursor-pointer ml-10 mb-10"
      />
      <h2 className="text-4xl font-bold mb-4 text-center">
        Create Message Template
      </h2>
      <div className="md:flex md:justify-center">
        <form
          onSubmit={formik.handleSubmit}
          className="grid grid-cols-1 shadow-lg rounded-xl mx-10 md:w-1/2 bg-slate-50 p-5"
        >
          <div className="mb-6">
            <div className="grid grid-cols-1 ">
              <div className="mb-2 col-span-1">
                <label htmlFor="clientID" className="text-xl ml-1 block">
                  TemplateID
                  <span className="text-lg text-red-500 ml-1">*</span>
                </label>
                <input
                  type="text"
                  name="templateId"
                  placeholder="Enter templateId"
                  value={formik.values.templateId}
                  onChange={formik.handleChange}
                  className="rounded-xl p-4 border w-full bg-white "
                  required={true}
                />
                {formik.touched.templateId && formik.errors.templateId && (
                  <div className="text-red-500 mb-1 ml-1 error text-md">
                    {formik.errors.templateId}
                  </div>
                )}
              </div>
              <div className="flex items-center justify-start  col-span-1">
                <button
                  type="button"
                  onClick={() =>
                    handleMessageTemplate(formik.values.templateId)
                  }
                  className="font-bold py-3 px-8 bg-blue-500 w-full flex items-center justify-center text-md text-white mt-5 rounded-md md:w-1/2 hover:bg-blue-600"
                >
                  Find Template
                </button>
              </div>
            </div>
          </div>

          {fields && sampleTemplate && template !== "" && (
            <div>
              <div className="bg-gray-white">
                <div className="flex flex-col">
                  <hr className="h-px my-4 bg-gray-200 border-0" />
                  {/* <div className="mb-4">
                    <h3 className="text-2xl font-bold">Fields:</h3>
                    <pre className="text-xl m-2 p-2 shadow-md rounded-xl bg-white text-semibold text-green-500 w-full whitespace-pre-wrap break-words">
                      {JSON.stringify(fields?.data || fields, null, 2)}
                    </pre>
                  </div> */}
                  <div className="w-full flex flex-col  mt-4 mb-4">
                    <h3 className="text-2xl font-bold">Fields:</h3>
                    <ul className="bg-white shadow-sm w-full lg:w-1/2 p-4 mb-2 rounded-2xl opacity-90 hover:cursor-not-allowed">
                      <li className="border-none p-0 w-full">
                        <div className="flex justify-between px-10 items-center w-full">
                          <strong className="text-2xl ">key</strong>
                          <strong className="text-2xl  ">Type</strong>
                        </div>
                        <hr className="h-px my-4 bg-gray-200 border-0" />
                      </li>
                      {Object.entries(fields).map(([key, value]) => (
                        <li key={key} className="border-none p-0 w-full">
                          <div className="flex justify-between  px-10 items-center w-full">
                            <span className="text-xl font-semibold text-gray-400">
                              {key}
                            </span>
                            <span className="text-xl font-semibold  text-gray-400">
                              {value}
                            </span>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <hr className="h-px my-4 bg-gray-200 border-0" />
                  <div className="mb-4">
                    <h3 className="text-2xl font-bold">Sample Template:</h3>
                    <pre className="text-xl m-2 p-2 shadow-sm rounded-xl  overflow-wrap text-wrap bg-white font-semibold opacity-90 hover:cursor-not-allowed text-gray-400 w-full whitespace-pre-wrap break-words">
                      {JSON.stringify(
                        sampleTemplate?.data || sampleTemplate,
                        null,
                        2
                      )}
                    </pre>
                  </div>
                </div>
              </div>
              <div className="flex items-center md:justify-center  col-span-1">
                <Button
                  name="Create Template"
                  classNames="bg-blue-500 w-full flex items-center justify-center text-md text-white mt-5 rounded-md md:w-1/3 hover:bg-blue-600"
                />
              </div>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default CreateMessageTemplate;
