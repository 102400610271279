import { configureStore } from "@reduxjs/toolkit";
import { apiSlice } from "./slices/apislice"
import DateAndTimesliceReducer from "./slices/DateAndTimeslice";
import authsliceReducer from "./slices/authslice";
import { getDateWithTimeEndpoint } from "./utils/utilityFunctions";

// const persistConfig = {
//   key: 'root',
//   storage, 
//   whitelist: ['dateandtime']
// };

// const persistedReducer = persistReducer(persistConfig, rootReducer);

// const store = configureStore({
//   reducer: persistedReducer,
//   middleware: (getDefaultMiddleware) =>
//     getDefaultMiddleware().concat(apiSlice.middleware),
//   devTools: true,
// });

// export const persistor = persistStore(store);
// export { store };

// final use ***************
// const store = configureStore({
//   reducer: {
//     [apiSlice.reducerPath]: apiSlice.reducer,
//     auth: authsliceReducer,
//     dateandtime: DateAndTimesliceReducer,

//   },
//   middleware: (getDefaultMiddleware) =>
//     getDefaultMiddleware().concat(apiSlice.middleware),
//   devTools: true,
// });

// export { store };
// final use ***************



// // Helper function to save the dateandtime slice to localStorage
// const saveDateAndTimeToLocalStorage = (state) => {
//   try {
//     const serializedState = JSON.stringify(state);
//     localStorage.setItem("dateandtime", serializedState);
//     console.log("i am inside it");
//   } catch (error) {
//     console.error("Error saving dateandtime state to localStorage:", error);
//   }
// };

// // Helper function to load the dateandtime slice from localStorage
// const loadDateAndTimeFromLocalStorage = () => {
//   try {
//     const serializedState = localStorage.getItem("dateandtime");
//     if (serializedState === null) {
//       return undefined; // Return undefined if no state is found in localStorage
//     }

//     // Parse the state from localStorage
//     const loadedState = JSON.parse(serializedState);
//     console.log("fromloadDate",loadedState);
//     return loadedState;
//   } catch (error) {
//     console.error("Error loading dateandtime state from localStorage:", error);
//     return undefined; // Return undefined in case of an error
//   }
// }


// Load dateandtime slice from localStorage
// const dateAndTimePreloadedState = loadDateAndTimeFromLocalStorage();

const { fromdate, todate } = getDateWithTimeEndpoint(1);
const preloadedState = {
  dateandtime: {
    selectedDatefrom: fromdate,
    selectedDateto: todate,
    selectedOption: 'Today',
  },
};


const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    auth: authsliceReducer,
    dateandtime: DateAndTimesliceReducer,
  },
  preloadedState, 
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware),
  devTools: true,
});

// Subscribe to dateandtime slice changes to save it to localStorage
// store.subscribe(() => {
//   const dateAndTimeState = store.getState().dateandtime;
//   console.log(dateAndTimeState);
//   saveDateAndTimeToLocalStorage(dateAndTimeState);
// });

export { store };
