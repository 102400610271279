import React from "react";
import { useSelector } from "react-redux";

const Footer = () => {
  const { userInfo } = useSelector((state) => state.auth);
  return (
    <>
      <footer
        className={
          userInfo
            ? "bg-light text-center text-lg-start btmfooter-afterlogin"
            : "bg-light text-center text-lg-start btmfooter"
        }
      >
        {/* <footer className="bg-light text-center text-lg-start btmfooter"> */}
        <div
          className="text-center p-3"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}
        >
          © 2023 Copyright
          <a className="text-dark mx-2" href="https://www.kugelblitz.xyz/">
            Kugelblitz.in
          </a>
        </div>
      </footer>
    </>
  );
};

export default Footer;
