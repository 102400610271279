import Button from "./Button";
import { NavLink } from "react-router-dom";


function ServiceCard(props) {
  return (
    <div className='custom-card bg-primary flex flex-col justify-center items-center rounded-2xl'>
      <div className="card-body flex flex-col gap-4 text-white items-center">
        <h2 className="text-2xl font-light">{props.modulename}</h2>
        <h1 className="text-xl font-bold">{props.datalogCount}</h1>
        <h3 className="text-3xl font-light">₹ {props.price}</h3>
        <NavLink to={`/report/${props.moduleid}`}><Button name="Report"/></NavLink>
      </div>
    </div>
  );
}

export default ServiceCard;