import { useState } from "react";
import { useFormik } from "formik";

import Button from "../../components/Button";
import {
  defaultValues,
  detailFieldStruct,
  gravitonDetailsFieldStruct,
  headersFieldStruct1,
  headersFieldStruct2,
  paymentStruct1,
  paymentStruct2,
  settlementAccountFieldStruct,
} from "../../utils/onboardClientsFunctions";
import {
  clientIdSchemaOnboarding,
  onboardClientSchema,
} from "../../formSchemas";
import { useGetApplicationIdAndOtherDetailsQuery } from "../../slices/getqueries/getqueriesApi";
import { toast } from "react-toastify";
import { useOnboardClientMutation } from "../../slices/postqueries/postqueriesApi";
import { useNavigate } from "react-router-dom";
// import { useSelector } from "react-redux";

const OnboardClient = () => {
  const navigate = useNavigate();
  const [submission, setSubmission] = useState(null);
  const [useDefaultValues, setUseDefaultValues] = useState(false);

  //fetching applicationIDNumber, random accountnumber and random clientSecretnumber
  const { error, isLoading, refetch } =
    useGetApplicationIdAndOtherDetailsQuery();

  //posting clientData
  const [onboardClient] = useOnboardClientMutation();

  //initial values of form depending on whether default values are used or not
  const initialValues = useDefaultValues
    ? defaultValues
    : {
        clientId: "",
        clientName: "",
        email: "",
        registeredMobileNo: "",
        activeMobileNo: "",
        panNo: "",
        panDocument: "",
        gstNo: "",
        gstCertificate: "",
        registrationCertifictae: "",
        otherDocumentOne: "",
        otherDocumentTwo: "",
        vendor: "",
        params: {},
        xApiVersion: "",
        xClientId: "",
        xClientSecret: "",
        vendor2: "",
        params2: {},
        client_id: "",
        client_secret: "",
        module_secret: "",
        provider_secret: "",
        name: "",
        accountNumber: "",
        accountIfsc: "",
        resourceUrl: "",
        tenantName: "",
        orgLogo: "",
        orgReportHeader: "",
        orgApplicationId: "",
      };

  //validation schema depending on whether default values are used or not
  const validationSchema = useDefaultValues
    ? clientIdSchemaOnboarding
    : onboardClientSchema;
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (values, actions) => {
      //refetching accountnumber, clientSecretNumber and applicationIDNumber each time for new submission
      const { data: fetchedData } = await refetch();

      if (!fetchedData) {
        console.log("Failed to fetch data");
        toast.error(`Failed to fetch data for default values`);
        return;
      }
      const client = values.clientId;

      //creating request body
      const reqBody = useDefaultValues
        ? defaultValues(
            client,
            fetchedData.clientNumber,
            fetchedData.accountNumber
          )
        : {
            clientId: values.clientId,
            clientName: values.clientName,
            email: values.email,
            registeredMobileNo: values.registeredMobileNo,
            activeMobileNo: values.activeMobileNo,
            panNo: values.panNo,
            panDocument: values.panDocument,
            gstNo: values.gstNo,
            gstCertificate: values.gstCertificate,
            registrationCertifictae: values.registrationCertifictae,
            otherDocumentOne: values.otherDocumentOne,
            otherDocumentTwo: values.otherDocumentTwo,
            payments: [
              {
                vendor: values.vendor,
                params: values.params,
                headers: {
                  "x-api-version": values.xApiVersion,
                  "x-client-id": values.xClientId,
                  "x-client-secret": values.xClientSecret,
                },
              },
              {
                vendor: values.vendor2,
                params: values.params2,
                headers: {
                  client_id: values.client_id,
                  client_secret: values.client_secret,
                  module_secret: values.module_secret,
                  provider_secret: values.provider_secret,
                },
              },
            ],
            settlementAccount: [
              {
                name: values.name,
                accountNumber: values.accountNumber,
                accountIfsc: values.accountIfsc,
              },
            ],
            messagingVendors: [],
            gravitonDetails: {
              resourceUrl: values.resourceUrl,
              tenantName: values.tenantName,
              orgLogo: values.orgLogo,
              orgReportHeader: values.orgReportHeader,
              orgApplicationId: values.orgApplicationId,
            },
          };

      //creating proper clientSecret with clientId present in form and secret number coming from backend
      const clientSecret = client + fetchedData.clientSecret;
      try {
        const res = await onboardClient({
          reqBody,
          clientId: client,
          clientSecret: clientSecret,
        }).unwrap();

        if (!res.error) {
          toast.success("Successfully onboarded client!", {
            autoClose: 2000,
          });
          setSubmission(res.data);
        } else {
          toast.error(`Error onboarding client : ${res.error.error}`, {
            autoClose: 2000,
          });
          setSubmission(null);
        }
        actions.resetForm();
        actions.setFieldValue("clientId", "");
      } catch (error) {
        console.log(error);
      }
    },
  });

  const handleCheckboxChange = () => {
    setUseDefaultValues(!useDefaultValues);
  };

  const detailFields = detailFieldStruct;
  const paymentField1 = paymentStruct1;
  const headersField1 = headersFieldStruct1;
  const paymentField2 = paymentStruct2;
  const headersField2 = headersFieldStruct2;
  const settlementAccountField = settlementAccountFieldStruct;
  const gravitonDetailsField = gravitonDetailsFieldStruct;

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;
  const handleGoBack = () => {
    navigate(-1);
  };
  return (
    <div className="w-full h-full">
      <Button
        name="< Go back"
        onClick={handleGoBack}
        classNames="bg-blue-500 text-white px-3 py-1 hover:bg-blue-600 hover:cursor-pointer ml-10 mb-5"
      />
      <h2 className="text-4xl font-bold mb-4 text-center">Onboarding Client</h2>
      <form
        onSubmit={formik.handleSubmit}
        className="shadow-lg rounded-xl mx-10 bg-slate-50 p-5"
      >
        <div className="mb-6 flex items-center">
          <input
            type="checkbox"
            id="useDefaultValues"
            checked={useDefaultValues}
            onChange={handleCheckboxChange}
          />
          <label htmlFor="useDefaultValues" className="text-lg px-2">
            Use Default Values
          </label>
        </div>
        {useDefaultValues && (
          <>
            <p className="text-red-500">
              Note: Some fields are hidden when using default values
            </p>
            <div className="mb-6">
              <label
                htmlFor="Details"
                className="text-3xl font-medium block mb-3"
              >
                Details
              </label>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
                {detailFields.map((field) => {
                  if (field.name === "clientId") {
                    return (
                      <div key={field.name} className="mb-6">
                        <label
                          htmlFor={field.name}
                          className="text-lg ml-1 block"
                        >
                          {field.label}
                          <span className="text-lg text-red-500 ml-1">*</span>
                        </label>
                        <input
                          type="text"
                          name={field.name}
                          placeholder={field.label}
                          value={formik.values[field.name]}
                          onChange={formik.handleChange}
                          className={`rounded-xl p-4 border w-full`}
                          required={true}
                        />
                        {formik.touched.clientId && formik.errors.clientId && (
                          <div className="text-red-500 mb-1 error text-md">
                            {formik.errors.clientId}
                          </div>
                        )}
                      </div>
                    );
                  }
                  return null;
                })}
              </div>
            </div>
          </>
        )}

        {!useDefaultValues && (
          <>
            <div className="mb-6">
              <label
                htmlFor="Details"
                className="text-3xl font-medium block mb-3"
              >
                Details
              </label>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
                {detailFields.map((field) => (
                  <div key={field.name} className="mb-6">
                    <label htmlFor={field.name} className="text-lg ml-1 block">
                      {field.label}
                      {field.label !== "Other Document 1" &&
                        field.label !== "Other Document 2" && (
                          <span className="text-lg text-red-500 ml-1">*</span>
                        )}
                    </label>
                    <input
                      type="text"
                      name={field.name}
                      placeholder={field.label}
                      value={formik.values[field.name]}
                      onChange={formik.handleChange}
                      className={`rounded-xl p-4 border w-full`}
                      required={
                        field.label !== "Other Document 1" &&
                        field.label !== "Other Document 2"
                      }
                    />
                    {formik.touched[field.name] &&
                      formik.errors[field.name] && (
                        <div className="text-red-500 mb-1 error text-md">
                          {formik.errors[field.name]}
                        </div>
                      )}
                  </div>
                ))}
              </div>
            </div>
            <hr className="h-px my-4 bg-gray-200 border-0" />

            <div className="mb-6">
              <label
                htmlFor="Payments"
                className="text-3xl font-medium  block mb-3"
              >
                Payments
              </label>
              <div className="space-y-4">
                <div className="grid grid-cols-1 sm:grid-cols-2  gap-4">
                  {paymentField1.map((field) => (
                    <div key={field.name} className="mb-6">
                      <label
                        htmlFor={field.name}
                        className="text-lg ml-1 block"
                      >
                        {field.label}
                        {field.label !== "Params" && (
                          <span className="text-lg text-red-500 ml-1">*</span>
                        )}
                      </label>
                      <input
                        type="text"
                        name={field.name}
                        placeholder={field.label}
                        value={
                          formik.values[field.name] &&
                          typeof formik.values[field.name] === "object"
                            ? JSON.stringify(formik.values[field.name]) === "{}"
                              ? ""
                              : "[object Object]"
                            : formik.values[field.name]
                        }
                        onChange={formik.handleChange}
                        className="rounded-xl p-4 border w-full"
                        required={field.label !== "Params"}
                      />
                      {formik.touched[field.name] &&
                        formik.errors[field.name] && (
                          <div className="text-red-500 mb-1 error text-md">
                            {formik.errors[field.name]}
                          </div>
                        )}
                    </div>
                  ))}
                </div>
                <div className="flex flex-wrap gap-4">
                  <label
                    htmlFor="Headers"
                    className="self-center text-lg ml-1 block"
                  >
                    Headers:
                  </label>
                  {headersField1.map((field) => (
                    <div key={field.name} className="mb-6">
                      <label
                        htmlFor={field.name}
                        className="text-lg ml-1 block"
                      >
                        {field.label}
                        <span className="text-lg text-red-500 ml-1">*</span>
                      </label>
                      <input
                        type="text"
                        name={field.name}
                        placeholder={field.label}
                        value={formik.values[field.name]}
                        onChange={formik.handleChange}
                        className="rounded-xl p-4 border w-full"
                        required
                      />
                      {formik.touched[field.name] &&
                        formik.errors[field.name] && (
                          <div className="text-red-500 mb-1 error text-md">
                            {formik.errors[field.name]}
                          </div>
                        )}
                    </div>
                  ))}
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                  {paymentField2.map((field) => (
                    <div key={field.name} className="mb-6">
                      <label
                        htmlFor={field.name}
                        className="text-lg ml-1 block"
                      >
                        {field.label}
                        {field.label !== "Params2" && (
                          <span className="text-lg text-red-500 ml-1">*</span>
                        )}
                      </label>
                      <input
                        type="text"
                        name={field.name}
                        placeholder={field.label}
                        value={
                          formik.values[field.name] &&
                          typeof formik.values[field.name] === "object"
                            ? JSON.stringify(formik.values[field.name]) === "{}"
                              ? ""
                              : "[object Object]"
                            : formik.values[field.name]
                        }
                        onChange={formik.handleChange}
                        className="rounded-xl p-4 border w-full"
                        required={field.label !== "Params2"}
                      />
                      {formik.touched[field.name] &&
                        formik.errors[field.name] && (
                          <div className="text-red-500 mb-1 error text-md">
                            {formik.errors[field.name]}
                          </div>
                        )}
                    </div>
                  ))}
                </div>
                <div className="flex flex-wrap gap-4 ">
                  <label
                    htmlFor="Headers"
                    className="self-center text-lg ml-1 block"
                  >
                    Headers:
                  </label>
                  {headersField2.map((field) => (
                    <div key={field.name} className="mb-6">
                      <label
                        htmlFor={field.name}
                        className="text-lg ml-1 block"
                      >
                        {field.label}
                        <span className="text-lg text-red-500 ml-1">*</span>
                      </label>
                      <input
                        type="text"
                        name={field.name}
                        placeholder={field.label}
                        value={formik.values[field.name]}
                        onChange={formik.handleChange}
                        className="rounded-xl p-4 border w-full"
                        required
                      />
                      {formik.touched[field.name] &&
                        formik.errors[field.name] && (
                          <div className="text-red-500 mb-1 error text-md">
                            {formik.errors[field.name]}
                          </div>
                        )}
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <hr className="h-px my-4 bg-gray-200 border-0" />

            <div className="mb-6">
              <label
                htmlFor="Settlement Account"
                className="text-3xl font-medium  block mb-3"
              >
                Settlement Account
              </label>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                {settlementAccountField.map((field) => (
                  <div key={field.name} className="mb-6">
                    <label htmlFor={field.name} className="text-lg ml-1 block">
                      {field.label}
                      <span className="text-lg text-red-500 ml-1">*</span>
                    </label>
                    <input
                      type="text"
                      name={field.name}
                      placeholder={field.label}
                      value={formik.values[field.name]}
                      onChange={formik.handleChange}
                      className="rounded-xl p-4 border w-full"
                      required
                    />
                    {formik.touched[field.name] &&
                      formik.errors[field.name] && (
                        <div className="text-red-500 mb-1 error text-md">
                          {formik.errors[field.name]}
                        </div>
                      )}
                  </div>
                ))}
              </div>
            </div>

            <hr className="h-px my-4 bg-gray-200 border-0" />
            <div className="mb-6">
              <label
                htmlFor="Graviton Details"
                className="text-3xl font-medium  block mb-3"
              >
                Messaging Vendors
              </label>
            </div>
            <hr className="h-px my-4 bg-gray-200 border-0" />

            <div className="mb-6">
              <label
                htmlFor="Graviton Details"
                className="text-3xl font-medium  block mb-3"
              >
                Graviton Details
              </label>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
                {gravitonDetailsField.map((field) => (
                  <div key={field.name} className="mb-6">
                    <label htmlFor={field.name} className="text-lg ml-1 block">
                      {field.label}
                      <span className="text-lg text-red-500 ml-1">*</span>
                    </label>
                    <input
                      type="text"
                      name={field.name}
                      placeholder={field.label}
                      value={formik.values[field.name]}
                      onChange={formik.handleChange}
                      className="rounded-xl p-4 border w-full"
                      required
                    />
                    {formik.touched[field.name] &&
                      formik.errors[field.name] && (
                        <div className="text-red-500 mb-1 error text-md">
                          {formik.errors[field.name]}
                        </div>
                      )}
                  </div>
                ))}
              </div>
            </div>
          </>
        )}

        <div className="flex items-center justify-center mb-6">
          <Button
            name="Submit"
            classNames="bg-blue-500 w-1/2 flex items-center justify-center text-md text-white mt-5 rounded:md md:w-1/4 hover:bg-blue-600"
          />
        </div>
        <div className="bg-gray-white">
          {submission && (
            <div className=" flex flex-col">
              <hr className="h-px my-4 bg-gray-200 border-0" />

              <div className="flex justify-between items-center">
                <h3 className="text-2xl">Submission Response:</h3>
                <Button
                  name="Close"
                  classNames="bg-red-500 text-md text-white hover:bg-red-600"
                  onClick={() => setSubmission(null)}
                />
              </div>
              <pre className="text-2xl m-2 p-2 shadow-md rounded-xl bg-white text-semibold text-green-500">
                {JSON.stringify(submission, null, 2)}
              </pre>
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

export default OnboardClient;
