export const BASE_URL = "https://services.graviton.kugelblitz.in/";
// export const BASE_URL = "http://localhost:9005/";
export const GROUP_URL = "callreport/group-and-subgroupdata";
export const GETCLIENT_CREDIT = "callreport/getclientCredit";
export const GETCLIENT_CURRENTPLAN = "callreport/getPricingDetails";
export const SERVICE_URL = "callreport/servicedata";
export const USERS_URL = "callreport/auth";
export const ALL_CLIENTS = "callreport/getAllClients";
export const GET_FINANCIAL_REPORT = "callreport/getFinancialReport";
export const GETSERVICE_RESPONSE = "callreport/getServiceResponse";

export const GETCIBIL_CREDENTIAL = "callreport/getCredentials";
export const CREATECIBIL_CREDENTIAL = "callreport/createCredentials";
export const UPDATECIBIL_CREDENTIAL = "callreport/updateCredentials";
export const TRANSACTIONS_URL = "callreport/getTransactionHistory";
export const CODEVALUES_URL = "callreport/getCodeValues";
export const CREATETEMPLATE_URL = "callreport/createMessagingTemplate";
export const ALL_MESSAGE_TEMPLATES_URL = "callreport/getTemplatesWithLimit";
export const DELETE_TEMPLATE_URL = "callreport/deleteTemplateWithId";
export const GET_ORG_APPILICATION_ID_AND_ACCOUNT_NUMBER =
  "callreport/getApplicationIdAndOtherDetails";

export const CLIENT_ONBOARD = "graviton/clients/onboard";
export const ONBOARDED_CLIENT_CREDENTIALS =
  "callreport/onboardedClientCredentials";
export const GRAVITON_DETAILS = "callreport/gravitonDetails";
export const MSG91_MESSAGE_TEMPLATE = "callreport/getMessagingTemplateMsg91";
export const REVENUEDETAILS = "callreport/revenueDetails";

export const SERVICEUSAGE = "callreport/servicesUsedByClient";
