import React from "react";
import ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./store";
import App from "./App";
import { StyledEngineProvider } from "@mui/material/styles";
import ReportScreen from "./screens/ReportScreen";
import reportWebVitals from "./reportWebVitals";
import HomeScreen from "./screens/HomeScreen";
import LoginScreen from "./screens/LoginScreen";
import PrivateRoute from "./components/PrivateRoutes";
import ResetLink from "./screens/resetLink";
import ResetPassword from "./screens/changePassword";
import FinancialReport from "./screens/FinancialReport";
import Currentplan from "./screens/CurrentPlan";
import Recharge from "./screens/Recharge";
import TransactionHistory from "./screens/TransactionHistory";

import CreateMessageTemplate from "./screens/messagingScreens/CreateMessageTemplate";
import MessageTemplates from "./screens/messagingScreens/MessageTemplates";
import OnboardClient from "./screens/onboardingScreens/OnboardClient";
import OnboardingScreen from "./screens/onboardingScreens/OnboardingScreen";
import ClientCredentials from "./screens/onboardingScreens/ClientCredentials";
import GravitonDetails from "./screens/onboardingScreens/GravitonDetails";
import MessagingScreen from "./screens/messagingScreens/MessagingScreen";
import CibilCredential from "./screens/CibilCredential";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />}>
      <Route path="login" element={<LoginScreen />} />
      <Route path="forgot" element={<ResetLink />} />
      <Route path="resetPassword" element={<ResetPassword />} />
      <Route path="" element={<PrivateRoute />}>
        <Route index={true} path="/" element={<HomeScreen />} />
        <Route path="report/:modid" element={<ReportScreen />} />
        <Route path="cibilcredential" element={<CibilCredential />} />
        <Route path="financialreport" element={<FinancialReport />} />
        <Route path="currentplan" element={<Currentplan />} />
        <Route path="recharge" element={<Recharge />} />
        <Route path="transactionHistory" element={<TransactionHistory />} />
        <Route path="messaging" element={<MessagingScreen />} />

        <Route path="/create-template" element={<CreateMessageTemplate />} />
        <Route path="/allTemplates" element={<MessageTemplates />} />
        <Route path="/onboardingclient" element={<OnboardingScreen />} />
        <Route path="/onboard-client" element={<OnboardClient />} />
        <Route
          path="/onboarded-client-credentials"
          element={<ClientCredentials />}
        />
        <Route
          path="/onboarded-client-graviton-details"
          element={<GravitonDetails />}
        />
      </Route>
    </Route>
  )
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <StyledEngineProvider injectFirst>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </StyledEngineProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
