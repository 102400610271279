import { useFormik } from "formik";
import React from "react";
import { rechargeSchema } from "../formSchemas";
import DropDown from "../components/DropDown";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useGetAllClientsQuery } from "../slices/getqueries/getqueriesApi";
import {
  useOtpMutation,
  useVerifyOtpMutation,
} from "../slices/postqueries/postqueriesApi";

const initialValues = {
  amount: 0,
  otp: 0,
};
const options = ["pulkit", "ashish"];

const Recharge = () => {
  const { userInfo } = useSelector((state) => state.auth);
  const { clientId } = userInfo;
  const [client, setClient] = useState(clientId);
  const [transactionid, setTransactionid] = useState("");
  const [admin, setAdmin] = useState("pulkit");
  const [adminphone, setAdminPhone] = useState("7300412230");
  const [otpSent, setOtpSent] = useState(false);
  const [otp, { isLoading, error }] = useOtpMutation();
  const [VerifyOtp, { isLoadingVerifyOtp, errorOtp }] = useVerifyOtpMutation();
  const {
    data: getAllClients,
    isLoading: loadingAllClients,
    error: errorAllClients,
  } = useGetAllClientsQuery({ clientname: clientId });

  const [countdown, setCountdown] = useState(180);

  useEffect(() => {
    let interval;

    if (otpSent && countdown > 0) {
      interval = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [otpSent, countdown]);

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema: rechargeSchema,
      onSubmit: async (values, action) => {
        console.log({ ...values, clientname: client, adminphone });
        try {
          const res = await VerifyOtp({
            adminphone,
            client,
            amount: values.amount,
            otp: values.otp,
            transactionid,
          }).unwrap();
          toast.success("Successfully Updated your data");
          setOtpSent(false);
          action.resetForm();
        } catch (err) {
          console.log(err);
          toast.error(err?.data?.detail || err.status);
        }
      },
    });

  const options2 = getAllClients?.map((item) => {
    return item.clientname;
  });

  const handleSendOtp = async (event) => {
    console.log(adminphone);
    event.preventDefault();
    try {
      const res = await otp({
        adminphone,
        client,
        amount: values.amount,
      }).unwrap();
      toast.success("otp has been send successfully!");
      setOtpSent(true);
      setCountdown(180);
      setTimeout(() => {
        setOtpSent(false);
      }, 180000);
      console.log(res.transaction_id);
      setTransactionid(res.transaction_id);
    } catch (err) {
      console.log(err);
      toast.error(err?.data?.detail || err.status);
    }
  };
  const handleAdminChange = (event) => {
    setAdmin(event.target.value);
    if (event.target.value === "pulkit") {
      setAdminPhone("7300412230");
    } else {
      setAdminPhone("9483033085");
    }
  };
  const handleClientChange = (event) => {
    setClient(event.target.value);
  };

  return (
    <div className="rechargepagecontainer w-full md:w-1/2 ">
      <h1 className="text-3xl font-bold flex flex-row justify-center text-center sm:text-left">
        Client Recharge
      </h1>
      <form onSubmit={handleSubmit} className="flex flex-col gap-y-2">
        <div className="flex flex-col gap-y-2 ">
          <DropDown
            selectedOption={client}
            options={options2}
            handleChange={handleClientChange}
            type={"Select Client"}
            addedClass={"ml-0 md:w-auto"}
          />
          {errorAllClients && <p>Error Loading Clients!</p>}
          <label
            htmlFor="amount"
            className="block mb-2 text-md font-medium text-gray-900 dark:text-white "
          >
            RechargeAmount
          </label>
          <input
            type="number"
            autoComplete="off"
            id="amount"
            name="amount"
            placeholder="RechargeAmount"
            className="bg-white ml-6 h-full mr-2 md:ml-2 md:mr-2 border  border-gray-400 text-gray-900 text-md rounded-lg focus:ring-gray-600 focus:border-gray-600 block p-5 "
            value={values.amount === 0 ? "" : values.amount}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {touched.amount && errors.amount && (
            <div className="ml-6 md:ml-2 error text-red-500">
              {errors.amount}
            </div>
          )}
        </div>
        <div className="flex items-center gap-x-8">
          <DropDown
            selectedOption={admin}
            options={options}
            handleChange={handleAdminChange}
            type={"Select Admin"}
            addedClass={"md:w-auto"}
          />
          <button
            type="button"
            onClick={handleSendOtp}
            disabled={otpSent || isLoading}
            className={`bg-${
              otpSent || isLoading ? "gray-300" : "green-500"
            } hover:bg-${
              otpSent || isLoading ? "gray-300" : "green-600"
            } text-${
              otpSent || isLoading ? "gray-500" : "white"
            } font-bold py-2 px-4 rounded self-end mb-4 cursor-${
              otpSent || isLoading ? "not-allowed" : "pointer"
            } `}
          >
            {isLoading ? "Sending" : "Send Otp"}
          </button>
          {/* {otpSent && <p>Time remaining: {countdown} seconds</p>} */}
          {otpSent && (
            <p className="mt-8 text-red-500">
              Time remaining: {countdown} seconds
            </p>
          )}
        </div>
        <div>
          <label
            htmlFor="otp"
            className="block mb-2 text-md font-medium text-gray-900 dark:text-white"
          >
            Enter Otp
          </label>
          <input
            type="number"
            value={values.otp === 0 ? "" : values.otp}
            autoComplete="off"
            id="otp"
            name="otp"
            onChange={handleChange}
            maxLength={6} // Set the maximum length to 6
            placeholder="Enter OTP"
            className="bg-white ml-6 mr-2 md:ml-2 md:mr-2 border border-gray-400 text-gray-900 text-md rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-4 "
            onBlur={handleBlur}
          />
          {touched.otp && errors.otp && (
            <div className="ml-6 mt-2 error text-red-500 md:ml-2 ">
              {errors.otp}
            </div>
          )}
        </div>
        <button
          className="bg-green-500 ml-6 mr-2 mt-2 md:ml-2 md:mr-2 self-start hover:bg-green-600 text-white font-bold py-2 px-4 rounded"
          type="submit"
        >
          VerifyOtp & Recharge
        </button>
      </form>
    </div>
  );
};

export default Recharge;
