import dayjs from "dayjs";
import React, { useMemo, useState, useEffect } from "react";
import Tablecomp from "../components/table/tablecomp";
import { useGetServiceDataQuery } from "../slices/getqueries/getqueriesApi";
import Button from "../components/Button";
import DateandTimePicker from "../components/DateandTimePicker";
import Loader from "../components/Loader";
import Message from "../components/Message";
import DropDown from "../components/DropDown";
import { changeDate, getDateWithTimeEndpoint } from "../utils/utilityFunctions";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { useParams } from "react-router-dom";
import statusCode from "../data/statusCode.json";
import {
  setSelectedDatefrom,
  setSelectedDateto,
  setSelectedOption,
} from "../slices/DateAndTimeslice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Viewreport from "../components/Viewreport";
import { data } from "autoprefixer";

const ReportScreen = () => {
  const navigate = useNavigate();
  const [serviceDataResponse, setServiceDataResponse] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(10);
  const { userInfo } = useSelector((state) => state.auth);
  const { clientId } = userInfo;
  const { modid } = useParams();
  const [date, setdate] = useState("");
  const options = [
    "Today",
    "LastTwoDays",
    "LastThreeDays",
    "LastWeek",
    "LastMonth",
    "LastTwoMonth",
  ];
  const [selectedStatus, setSelectedStatus] = useState("200-OK");
  const handlePageIndexChange = (index) => {
    setPage(index);
  };
  const dispatch = useDispatch();
  const { selectedDatefrom, selectedDateto, selectedOption } = useSelector(
    (state) => state.dateandtime
  );
  const {
    data: servicedata,
    isLoading,
    error: errorServiceData,
  } = useGetServiceDataQuery({
    timeendpoint: date,
    moduleid: modid,
    clientname: clientId,
    page: page,
  });
  useEffect(() => {
    if (servicedata) {
      if (servicedata.data) {
        setServiceDataResponse(servicedata.data);
        const totalItems = servicedata.totalCount;
        setLimit(servicedata.limit);
        const newTotalPages = Math.ceil(totalItems / limit);
        setTotalPages(newTotalPages);
      } else {
        setServiceDataResponse([]);
        setTotalPages(1);
      }
    }
  }, [servicedata, page]);
  useEffect(() => {
    if (serviceDataResponse.length === 0 && page > 1) {
      setPage((prevPage) => prevPage - 1);
      setTotalPages(page);
    }
  }, [serviceDataResponse, page, totalPages]);

  let servdata = useMemo(() => serviceDataResponse, [serviceDataResponse]);

  useEffect(() => {
    dispatch(setSelectedDatefrom(selectedDatefrom));
    dispatch(setSelectedDateto(selectedDateto));
    changeServicedatafordate();
  }, []);

  useEffect(() => {}, [date, servdata]);
  useEffect(() => {
    changeServicedatafordate();
  }, [page]);

  const handleDateTimeChangefrom = (date) => {
    dispatch(setSelectedDatefrom(date));
  };

  const handleDateTimeChangeto = (date) => {
    dispatch(setSelectedDateto(date));
  };

  const handleOptionChange = (days) => {
    let { endpoint, fromdate, todate } = getDateWithTimeEndpoint(days);
    endpoint = `${endpoint} + &statuscode=${selectedStatus.split("-")[0]}`;
    dispatch(setSelectedDatefrom(fromdate));
    dispatch(setSelectedDateto(todate));
    setdate(endpoint);
  };

  const handleChange = (event) => {
    dispatch(setSelectedOption(event.target.value));
    switch (event.target.value) {
      case "Today":
        handleOptionChange(1);
        break;
      case "LastTwoDays":
        handleOptionChange(2);
        break;
      case "LastThreeDays":
        handleOptionChange(3);
        break;
      case "LastWeek":
        handleOptionChange(7);
        break;
      case "LastMonth":
        handleOptionChange(30);
        break;
      case "LastTwoMonth":
        handleOptionChange(60);
        break;
      default:
        break;
    }
  };

  const handleChangeStatus = (event) => {
    setSelectedStatus(event.target.value);
    const formateddatefrom = changeDate(selectedDatefrom);
    const formateddateto = changeDate(selectedDateto);
    if (selectedDatefrom === null || selectedDateto === null) {
      const dateandstatus = `from=${formateddatefrom}&to=${formateddateto}&statuscode=${
        event.target.value.split("-")[0]
      }`;
      setdate(dateandstatus);
    } else {
      const dateandstatus = `from=${formateddatefrom}&to=${formateddateto}&statuscode=${
        event.target.value.split("-")[0]
      }`;
      setdate(dateandstatus);
    }
  };

  const changeServicedatafordate = () => {
    const formateddatefrom = changeDate(selectedDatefrom);
    const formateddateto = changeDate(selectedDateto);
    const dateandstatus = `from=${formateddatefrom}&to=${formateddateto}&statuscode=${
      selectedStatus.split("-")[0]
    }`;
    setdate(dateandstatus);
  };
  const { isAdmin, permissions } = userInfo;
  const columns = [
    {
      header: "RequestId",
      accessorKey: "requestid",
      footer: "ID",
    },
    {
      header: "ModuleName",
      accessorKey: "modulename",
    },
    {
      header: "StatusCode",
      accessorKey: "statuscode",
      footer: "StatusCode",
    },
    {
      header: "TransactionDate",
      accessorKey: "transactiondate",
      footer: "TransactionDate",
      cell: (info) => {
        const timestamp = info.getValue();
        const dateComponent = timestamp.split(" ")[0];
        return dateComponent;
      },
    },
    {
      header: "TransactionTime",
      accessorKey: "transactiondate",
      footer: "TransactionTime",
      cell: (info) => {
        const timestamp = info.getValue();
        const timeWithMilliseconds = timestamp.split(" ")[1];
        const timeWithoutMilliseconds = timeWithMilliseconds.split(".")[0];
        return timeWithoutMilliseconds;
      },
    },
    {
      header: "CreditLeft",
      accessorKey: "creditleft",
      footer: "CreditLeft",
    },
    ...(permissions.includes("GET_SERVICERESPONSE")
      ? [
          {
            id: "viewReport",
            header: "ViewReport",
            accessorKey: "ViewReport",
            footer: "ViewReport",
            cell: (info) => (
              <Viewreport requestId={info.row.original?.requestid} />
            ),
          },
        ]
      : []),
  ];

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : errorServiceData ? (
        <Message variant="danger">
          {errorServiceData?.data?.message || errorServiceData.error}
        </Message>
      ) : (
        <div className="reportcontainer">
          <div className="col-span-4 md:col-span-5 mr-8 justify-self-center self-center">
            <button
              className="w-full flex items-center justify-center px-5 py-4 text-md transition-colors duration-200 bg-blue-500 text-white border rounded-lg gap-x-2 sm:w-auto bg-primary hover:bg-blue-500 text-white font-bold"
              onClick={handleGoBack}
            >
              <svg
                class="w-5 h-5 rtl:rotate-180"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18"
                />
              </svg>
              <span>Go back</span>
            </button>
          </div>
          <div className="reportcontainer-filters flex flex-col md:flex md:flex-row md:justify-between md:mb-3">
            <DropDown
              selectedOption={selectedOption}
              options={options}
              handleChange={handleChange}
              type="Select Time"
            />
            <DropDown
              selectedOption={selectedStatus}
              options={statusCode.map(
                (status) => `${status.code}-${status.description}`
              )}
              handleChange={handleChangeStatus}
              type="Select Status"
            />
            <div className="date flex items-center ">
              <div className="date-fromdate px-2">
                <h2 className="text-xl">From</h2>
                <DateandTimePicker
                  onDateTimeChange={handleDateTimeChangefrom}
                  defaultDate={dayjs(selectedDatefrom)}
                />
              </div>
              <div className="date-todate px-2">
                <h2 className="text-xl">To</h2>
                <DateandTimePicker
                  onDateTimeChange={handleDateTimeChangeto}
                  defaultDate={dayjs(selectedDateto)}
                />
              </div>
              <div className="mt-10 px-2">
                <Button
                  name="Find"
                  onClick={changeServicedatafordate}
                  classNames="bg-primary hover:bg-blue-500 text-white"
                />
              </div>
            </div>
          </div>
          <div className="report-table-container">
            <Tablecomp
              data={servdata ? servdata : ""}
              columns={columns}
              onPageIndexChange={handlePageIndexChange}
              page={page}
              totalCount={servicedata.totalCount}
              limit={limit}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default ReportScreen;
