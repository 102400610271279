import { apiSlice } from "../apislice";
import {
  ALL_CLIENTS,
  ALL_MESSAGE_TEMPLATES_URL,
  CODEVALUES_URL,
  GET_ORG_APPILICATION_ID_AND_ACCOUNT_NUMBER,
  GETCIBIL_CREDENTIAL,
  GETCLIENT_CREDIT,
  GETCLIENT_CURRENTPLAN,
  GETSERVICE_RESPONSE,
  GROUP_URL,
  SERVICE_URL,
  TRANSACTIONS_URL,
  MSG91_MESSAGE_TEMPLATE,
  GRAVITON_DETAILS,
  REVENUREDETAILS,
  REVENUEDETAILS,
  SERVICEUSAGE,
} from "../../constants";

export const HomePageApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getGroupData: builder.query({
      query: ({ date, clientname }) => {
        // if (date !== "") {
        //   return {
        //     url: `${GROUP_URL}/${clientname}?${date}`,
        //   };
        // } else {
        //     const now = new Date();
        //     const twentyFourHoursAgo = new Date(now - 24 * 60 * 60 * 1000);

        //     const from = twentyFourHoursAgo.toISOString();
        //     const fromdate = changeDate(from);
        //     const to = now.toISOString();
        //     const todate = changeDate(to);
        return {
          url: `${GROUP_URL}/${clientname}?${date}`,
          // url: `${GROUP_URL}/${clientname}?from=${fromdate}&to=${todate}`,
        };
        // }
      },
      keepUnusedDataFor: 5,
    }),
    getServiceData: builder.query({
      query: ({ timeendpoint, moduleid, clientname, page }) => {
        return {
          url: `${SERVICE_URL}/${clientname}?${timeendpoint}&moduleid=${moduleid}&page=${page}`,
        };
      },
      keepUnusedDataFor: 5,
    }),
    getClientCredits: builder.query({
      query: (reqid) => ({
        url: `${GETCLIENT_CREDIT}/${reqid}`,
      }),
      keepUnusedDataFor: 5,
    }),
    getAllClients: builder.query({
      query: ({ clientname }) => ({
        url: `${ALL_CLIENTS}/${clientname}`,
      }),
      keepUnusedDataFor: 5,
    }),
    getCurrentPlan: builder.query({
      query: ({ clientname }) => ({
        url: `${GETCLIENT_CURRENTPLAN}/${clientname}`,
      }),
      keepUnusedDataFor: 5,
    }),
    getServiceResponse: builder.query({
      query: ({ requestId, clientname }) => ({
        url: `${GETSERVICE_RESPONSE}/${requestId}/${clientname}`,
      }),
      keepUnusedDataFor: 5,
    }),
    getCibilCredential: builder.query({
      query: ({ clientname }) => ({
        url: `${GETCIBIL_CREDENTIAL}/${clientname}`,
      }),
      keepUnusedDataFor: 5,
    }),
    getTransactionHistory: builder.query({
      query: ({ clientname }) => ({
        url: `${TRANSACTIONS_URL}/${clientname}`,
      }),
      keepUnusedDataFor: 5,
    }),
    getCodeValues: builder.query({
      query: () => ({
        url: `${CODEVALUES_URL}`,
      }),
      keepUnusedDataFor: 5,
    }),
    getTemplatesWithLimit: builder.query({
      query: ({ page, clientname }) => ({
        url: `${ALL_MESSAGE_TEMPLATES_URL}?page=${page}&clientname=${clientname}`,
      }),
      keepUnusedDataFor: 5,
    }),
    getApplicationIdAndOtherDetails: builder.query({
      query: () => ({
        url: `${GET_ORG_APPILICATION_ID_AND_ACCOUNT_NUMBER}`,
      }),
      keepUnusedDataFor: 5,
    }),
    getMessageTemplateMsg91: builder.query({
      query: ({ clientId, templateId }) => ({
        url: `${MSG91_MESSAGE_TEMPLATE}/${templateId}`,

        header: {
          client_id: clientId,
        },
      }),
      keepUnusedDataFor: 5,
    }),
    getRevenueDetails: builder.query({
      query: ({ clientId, year }) => ({
        url: `${REVENUEDETAILS}/${clientId}?year=${year}`,
      }),
      keepUnusedDataFor: 5,
    }),
    getServiceUsage: builder.query({
      query: ({ clientId, year, month }) => ({
        url: `${SERVICEUSAGE}/${clientId}?year=${year}&month=${month}`,
      }),
      keepUnusedDataFor: 5,
    }),
  }),
});
export const {
  useGetGroupDataQuery,
  useGetClientCreditsQuery,
  useGetServiceDataQuery,
  useGetAllClientsQuery,
  useGetCurrentPlanQuery,
  useGetServiceResponseQuery,
  useGetCibilCredentialQuery,
  useGetTransactionHistoryQuery,
  useGetCodeValuesQuery,
  useGetTemplatesWithLimitQuery,
  useGetApplicationIdAndOtherDetailsQuery,
  useGetMessageTemplateMsg91Query,
  useGetRevenueDetailsQuery,
  useGetServiceUsageQuery,
} = HomePageApiSlice;
