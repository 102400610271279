import { createSlice } from "@reduxjs/toolkit";
import { getDateWithTimeEndpoint } from "../utils/utilityFunctions";

const initialState = {
  selectedDatefrom: null,
  selectedDateto: null,
  selectedOption: null,
};

const DateAndTimeSlice = createSlice({
  name: "dateandtime",
  initialState,
  reducers: {
    setSelectedDatefrom: (state, action) => {
      state.selectedDatefrom = action.payload;
    },
    setSelectedDateto: (state, action) => {
      state.selectedDateto = action.payload;
    },
    setSelectedOption: (state, action) => {
      state.selectedOption = action.payload;
    },
    setAllNull: (state, action) => {
      state.selectedDatefrom = null;
      state.selectedDateto = null;
      state.selectedOption = null;
    },
    setInitialState: (state, action) => {
      const { fromdate, todate } = getDateWithTimeEndpoint(1);
      state.selectedDatefrom = fromdate;
      state.selectedDateto = todate;
      state.selectedOption = "Today";
    },
  },
});

export const {
  setSelectedDatefrom,
  setSelectedDateto,
  setSelectedOption,
  setAllNull,
  setInitialState,
} = DateAndTimeSlice.actions;
export default DateAndTimeSlice.reducer;
