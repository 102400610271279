import React from "react";

const Input = (props) => {
  const {
    htmlforval,
    labelval,
    typeval,
    placeholderval,
    valueval,
    onChangeHandler,
    addClasses,
  } = props;
  return (
    <div className={`relative ${addClasses}`}>
      <label
        htmlFor={htmlforval}
        className="block text-xl font-medium text-gray-600 absolute top-[-9px] left-[9px] bg-white px-2"
      >
        {labelval}
      </label>
      <input
        type={typeval}
        id={htmlforval}
        className="border text-lg border-[#41309B] rounded-xl py-6 px-4 w-full"
        placeholder={placeholderval}
        value={valueval}
        onChange={(e) => onChangeHandler(e.target.value)}
        required
      />
    </div>
  );
};

export default Input;
